import Icons from "@/components/Icons";
import { Box, Divider, Text } from "@chakra-ui/react";

const EmailReach = ({ campaignAnalytics }: { campaignAnalytics: any }) => {
  return (
    <Box className="w-[60%] bg-white rounded-lg p-4 border-[#F0EFF1] !shadow-lg h-[220px]">
      <div className="flex items-center gap-2">
        <Icons.InformationIcon className="text-[20px] text-[#5024DC]" />
        <Text className="text-[16px] text-[#373F51] font-medium">
          Overall Email Reached
        </Text>
      </div>

      <Divider className="my-3" />
      {/* Leads contacted */}
      <div className="flex items-center gap-3 mt-5">
        <div className="bg-[#F5F3FF] border border-[#DDD8FF] flex flex-col items-center justify-center rounded-[8px] h-[90px] w-[250px] py-[30px] px-[40px]">
          <p className="text-[24px] font-[600] text-[#090909]">
            {campaignAnalytics?.sent_count}
          </p>
          <p className="text-[12px] text-[#7A7A7A] font-[500]">
            Total Leads Contacted
          </p>
        </div>
        {/* Unique Replies */}
        <div className="bg-[#F5F3FF] border border-[#DDD8FF] flex flex-col items-center justify-center rounded-[8px] h-[90px] w-[250px] py-[30px] px-[40px]">
          <p className="text-[24px] font-[600] text-[#090909]">
            {campaignAnalytics?.reply_count}
          </p>
          <p className="text-[12px] text-[#7A7A7A] font-[500]">
            Unique Replies
          </p>
        </div>
        {/* Opened Messages */}
        <div className="bg-[#F5F3FF] border border-[#DDD8FF] flex flex-col items-center justify-center rounded-[8px] h-[90px] w-[250px] py-[30px] px-[40px]">
          <p className="text-[24px] font-[600] text-[#090909]">
            {campaignAnalytics?.open_count}
          </p>
          <p className="text-[12px] text-[#7A7A7A] font-[500]">
            Total Opened Messages
          </p>
        </div>
      </div>
    </Box>
  );
};

export default EmailReach;
