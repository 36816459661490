import { Box } from "@chakra-ui/react";
import AllCampaignsGrid from "./CampaignsList";

const CampaignsManager = () => {
  return (
    <Box className="mt-4">
      <AllCampaignsGrid />
    </Box>
  );
};

export default CampaignsManager;
