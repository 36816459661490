import Papa from "papaparse";
import { Descendant } from "slate";
import Cookies from "universal-cookie";
import { parse } from "tldts";

import { InputtedData } from "@/components/PeopleSearch/store";
import { createANewColumnName } from "@/components/Table/utils";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { Cell, Column } from "@/types/table.types";
import { SlateValue, ParagraphNode } from "@/types/presets.types";

export const isUrl = (cellValue: string) => {
  if (new RegExp("^(http://|https://|www\\.)").test(cellValue)) {
    return true;
  }
  const pattern = new RegExp(
    "^((https?:\\/\\/)?)" + // protocol (http or https)
      "(([a-z\\d]+(-[a-z\\d]+)*\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\/[\\w\\.&-]*)*\\/?$", // path
    "i",
  );
  return !!pattern.test(cellValue);
};

export const checkErrorOrLoading = (cell?: Cell) => {
  if (!cell) return true;
  if (typeof cell.value === "object" || typeof cell.value === "number")
    return false;
  return (
    cell.status === "error" ||
    cell.value?.toLowerCase?.()?.includes("queued...") ||
    cell.value?.toLowerCase?.()?.includes("condition did not match") ||
    false
  );
};
export const processCellValue = (cell?: Cell): string => {
  if (typeof cell?.value === "object") {
    return JSON.stringify(cell.value);
  } else if (typeof cell?.value === "string") {
    // TODO: handle this in backend
    if (cell?.value?.startsWith("❌"))
      return cell.value.split("❌", 2)[1] ?? cell.value;
    return cell?.value ?? "";
  }
  return `${cell?.value ?? ""}`;
};
export const convertObject = (input: {
  [key: string]: { value: string };
}): {
  [key: string]: string;
} => {
  const output: { [key: string]: string } = {};
  for (const key in input) {
    output[key] = input?.[key]?.value ?? "";
  }
  return output;
};

export const generateId = (text: string) => {
  return `${text || ""}-${Math.random().toString(36).substr(2, 9)}`;
};

export const replaceToBr = (str: string) => {
  return str.replace(/\n/g, "<br />");
};

export const extractNameAndContinent = (obj: any) => {
  const result = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const { name, continent } = obj[key];
      result.push({ label: name, value: continent });
    }
  }

  return result;
};

export const handlePushToArray = (
  value: any,
  stateToUpdate: any,
  setStateToUpdate: (state: any) => void,
) => {
  setStateToUpdate({
    ...stateToUpdate,
    [value?.name]: value?.value,
  });
};

export function filterValidObjKeys(obj: any) {
  if (typeof obj !== "object" || obj === null) {
    throw new Error("Input must be an object");
  }

  const result = {} as any;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "number") {
        if (value > 0) {
          result[key] = value;
        }
      }
      if (typeof value === "string") {
        if (
          typeof value === "boolean" ||
          (typeof value === "string" && value.trim() !== "")
        ) {
          result[key] = value;
        }
      } else if (typeof key === "object") {
        if (Object.keys(value).length > 0) {
          result[key] = value;
        }
      } else if (Array.isArray(value)) {
        if (value.length > 0) {
          result[key] = value;
        }
      }
    }
  }

  return result;
}

export const getInitialColumn = (regex: RegExp) => {
  const columns = useTableStore.getState().tableData?.columns;
  const rows = useTableStore.getState().rowsData;

  if (!columns || !rows) {
    return;
  }

  const matchedColumns = [] as {
    column: Column;
    matchCount: number;
  }[];

  columns.forEach((column) => {
    let matchCount = 0;
    rows.slice(0, 10).forEach((row) => {
      const cellValue = row.cells?.[column._id]?.value;
      if (typeof cellValue !== "string") return;
      if (row.cells?.[column._id]?.value.match(regex)) {
        matchCount++;
      }
    });

    if (matchCount >= 1) {
      matchedColumns.push({
        column,
        matchCount,
      });
    }
  });

  if (matchedColumns.length === 0) {
    return;
  }

  const mostMatchedColumn = matchedColumns?.reduce(
    (max, cell) => (cell.matchCount > max.matchCount ? cell : max),
    matchedColumns[0],
  );

  return mostMatchedColumn?.column;
};

export const preselectEnrichmentState = (
  selectedEnrichment: any,
  updateState: (state: TSelectedColumnOption) => void,
  dependentColumnId?: string,
) => {
  const comparisonItem = dependentColumnId
    ? dependentColumnId
    : selectedEnrichment["columnId"];
  const relyOnColumn = useTableStore
    .getState()
    .tableData?.columns.find((column) => column._id === comparisonItem);

  if (relyOnColumn) {
    updateState({
      key: relyOnColumn.name,
      keyId: relyOnColumn._id,
      iconType: relyOnColumn.metaData?.iconType || "url",
    });
  }
};

export const getInitialColumnByColName = (name: string) => {
  const columns = useTableStore.getState().tableData?.columns;

  if (!columns) {
    return;
  }

  // First, check for an exact match
  const exactMatch = columns.find(
    (column) => column.name?.toLocaleUpperCase() === name?.toLocaleUpperCase(),
  );

  if (exactMatch) {
    return exactMatch;
  }

  // If no exact match is found, check for the includes condition
  const includesMatch = columns.find((column) =>
    column.name?.toLocaleUpperCase()?.includes(name?.toLocaleUpperCase()),
  );

  return includesMatch;
};

export const updateInitialColumn = (
  value: string | RegExp,
  updateState: React.Dispatch<
    React.SetStateAction<TSelectedColumnOption | null>
  >,
) => {
  const initialColumn =
    typeof value === "string"
      ? getInitialColumnByColName(value)
      : getInitialColumn(value);

  if (initialColumn) {
    updateState({
      key: initialColumn.name,
      keyId: initialColumn._id,
      iconType: initialColumn.metaData?.iconType || "url",
    });
  }
};

export const getParsedCsvData = (file: any) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      worker: true,
      preview: 1,
      step: function (results: any) {
        const data = {
          columns: results.meta.fields,
        };
        // rows: results.data,
        resolve(data);
      },
      error: function (error: any, file: any) {
        console.log("mylog error", error, file);
        reject(error);
      },
    });
  });
};

export function convertTextToSlate(text: string): SlateValue {
  const slateValue: SlateValue = [];
  const lines = text.trim().split("\n");
  for (const line of lines) {
    const element: ParagraphNode = { type: "paragraph", children: [] };

    const regex = /{{(.*?)}}/g;
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(line)) !== null) {
      if (match.index > lastIndex) {
        const textPart = line.substring(lastIndex, match.index);
        element.children.push({ text: textPart });
      }

      const placeholderContent = match[1];

      element.children.push({
        type: "inlineVoid",
        children: [{ text: placeholderContent }],
      });

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < line.length) {
      const textPart = line.substring(lastIndex);
      element.children.push({ text: textPart });
    }
    if (element.children.length > 0) slateValue.push(element);
  }
  return slateValue;
}
export const convertSlateToText = (
  slateValue: Descendant[],
  useId: boolean = false,
) => {
  let result = "";
  const count = [] as { [key: string]: string }[] | any[];

  if (!slateValue) {
    return { text: "", count: [] };
  }

  slateValue.forEach((element: any) => {
    if (element.type === "paragraph") {
      element.children.forEach((child: any) => {
        if (child.type === "inlineVoid") {
          console.log("child.children", child);
          if (useId) {
            result += `{{${child.id}}}`;
            count.push({
              [child.id]: child.children[0].text,
            });
          } else {
            result += `{{${child.children[0].text}}}`;
            count.push(child.children[0].text);
          }
        } else {
          result += child.text;
        }
      });
      result += "\n";
    }
  });

  return { text: result, count };
};

export const extractVariables = (str: string) => {
  const regex = /{{(.*?)}}/g;
  let match;
  const values = [];
  while ((match = regex.exec(str)) !== null) {
    values.push(match[1]);
  }
  return values;
};

export function validateEmail(email: string) {
  if (typeof email !== "string" || email.trim() === "") return false;
  // Regular expression pattern for email validation
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the pattern
  return pattern.test(email);
}

export const setCookie = (
  key: string,
  value: string,
  expirationDate?: Date,
) => {
  const cookies = new Cookies();

  const isDevelopment = process.env.NODE_ENV === "development";

  // if no expiration date is provided, default to 1 year
  const expiredIn =
    expirationDate ||
    new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

  cookies.set(key, value, {
    path: "/", // Specify the path where the cookie is accessible
    secure: !isDevelopment,
    sameSite: isDevelopment ? "lax" : "none",
    // Note: 'httpOnly' cannot be set from client-side JavaScript
    expires: expiredIn,
    domain: isDevelopment ? "localhost" : ".persana.ai",
  });
};

export const isNameChanged = ({
  firstName,
  lastName,
  previousFirstName,
  previousLastName,
}: {
  firstName: string;
  lastName: string;
  previousFirstName: string;
  previousLastName: string;
}) => {
  if (previousFirstName === "NA" || previousLastName === "NA") {
    return "Y";
  }
  const fName = firstName.toLowerCase();
  const lName = lastName.toLocaleLowerCase();
  const prevFName = previousFirstName.toLocaleLowerCase();
  const prevLName = previousLastName.toLocaleLowerCase();
  const currentName = `${fName} ${lName}`;
  const previousName = `${prevFName} ${prevLName}`;

  if (
    currentName.includes(previousName) ||
    previousName.includes(currentName)
  ) {
    return "Y";
  }

  const isFirstNameChanged =
    fName.length <= prevFName.length
      ? !prevFName.includes(fName)
      : !fName.includes(prevFName);

  const isLastNameChanged =
    lName.length <= prevLName.length
      ? !prevLName.includes(lName)
      : !lName.includes(prevLName);

  return isFirstNameChanged || isLastNameChanged ? "N" : "Y";
};

export function convertPromptToContext(
  questionsArray: { prompt: string; answer: string }[],
) {
  if (questionsArray.length === 0) return "";

  let contextString = "";
  for (let i = 0; i < questionsArray.length; i++) {
    if (i !== 0) {
      contextString += "\n";
      contextString +=
        "Previous question: " + questionsArray[i - 1].prompt + "\n";
    }
    contextString += "Previous Question: " + questionsArray[i].prompt + "\n";
    contextString += "Answer: " + questionsArray[i].answer + "\n";
  }
  return contextString;
}

export const addNewColumn = async ({ columnName }: { columnName: string }) => {
  const newColumnName =
    createANewColumnName(
      columnName,
      useTableStore.getState().tableData?.columns,
    ) || columnName;

  const response = await tableService.insertColumn(
    useTableStore.getState().tableData?._id,
    {
      name: newColumnName,
    },
  );

  if (response.data?.success) {
    if (response?.data?.data) {
      useTableStore.getState().updateState({
        tableData: response.data.data,
      });
    }
  }
};

export const getNestedObjValue = (obj: any, path: string) => {
  return path
    ?.split(/[\.\[\]']+/)
    ?.filter(Boolean)
    ?.reduce((o, k) => (o || {})[k], obj);
};

function compareValues(more: boolean, less: boolean, value: string) {
  if (!more && !less) {
    return `eq:${value}`;
  } else if (more) {
    return `gt:${value}`;
  } else if (less) {
    return `lt:${value}`;
  }
}

export const convertSearchPeoplePayload = (inputtedData: InputtedData) => {
  return {
    person_titles: inputtedData.person_titles.map((title) => title?.label),
    person_not_titles: inputtedData.person_not_titles.map(
      (title) => title?.label,
    ),
    person_past_titles: inputtedData.person_past_titles.map(
      (title) => title?.label,
    ),
    organization_ids:
      inputtedData?.in_house_organizations?.length > 0
        ? inputtedData?.in_house_organizations?.map((company) => company?.value)
        : inputtedData.organization_ids.map(
            (company: any) =>
              company?.organization_id ??
              company?.id ??
              company?.owned_by_organization_id,
          ),
    not_organization_ids:
      inputtedData?.in_house_organizations_not?.length > 0
        ? inputtedData?.in_house_organizations_not?.map(
            (company) => company?.value,
          )
        : inputtedData.not_organization_ids.map(
            (company: any) =>
              company?.organization_id ??
              company?.id ??
              company?.owned_by_organization_id,
          ),
    person_past_organization_ids:
      inputtedData?.in_house_organizations_past?.length > 0
        ? inputtedData?.in_house_organizations_past?.map(
            (company) => company?.value,
          )
        : inputtedData.person_past_organization_ids.map(
            (company: any) =>
              company?.organization_id ??
              company?.id ??
              company?.owned_by_organization_id,
          ),
    organization_num_employees_ranges:
      inputtedData.organization_num_employees_ranges.map((size) =>
        size?.name?.replace("-", ","),
      ),
    q_organization_keyword_tags: inputtedData.q_organization_keyword_tags.map(
      (tag) => tag?.value,
    ),
    q_not_organization_keyword_tags:
      inputtedData.q_not_organization_keyword_tags.map((tag) => tag?.value),
    person_locations: inputtedData.person_locations.map(
      (location) => location?.label,
    ),
    person_not_locations: inputtedData.person_not_locations.map(
      (location) => location?.label,
    ),
    q_person_name: inputtedData.q_person_name,
    q_keywords: inputtedData.q_keywords,
    q_exclude_keywords: inputtedData.q_exclude_keywords,
    educations: inputtedData.educations.map(
      (education: any) => education?.name || education?.label,
    ),
    languages: inputtedData.languages.map(
      (language: any) => language?.name || language?.label,
    ),
    seniorities: inputtedData.seniorities.map((language) => language?.label),
    skills: inputtedData.skills.map((language) => language?.value),
    certifications: inputtedData.certifications.map(
      (language) => language?.value,
    ),
    followers: inputtedData?.followers
      ? compareValues(
          inputtedData?.isMoreFollowers,
          inputtedData?.isLessFollowers,
          inputtedData?.followers,
        )
      : "",
    employee_count: inputtedData?.employee_count
      ? compareValues(
          inputtedData?.isMoreEmployees,
          inputtedData?.isLessEmployees,
          inputtedData?.employee_count,
        )
      : "",
    company_sizes: inputtedData.company_sizes.map((size) => size?.value),
    company_types: inputtedData.company_types.map((type) => type?.value),
    included_industry_codes: inputtedData.included_industry_codes.map(
      (type) => type.value,
    ),
    excluded_industry_codes: inputtedData.excluded_industry_codes.map(
      (type) => type.value,
    ),
    min_num_connection: inputtedData.min_num_connection,
    min_month_in_current_role: inputtedData.min_month_in_current_role,
    max_month_in_current_role: inputtedData.max_month_in_current_role,
    industry_names: inputtedData.industries?.map((industry) => industry.label),
  } as any;
};

export const isValidURL = (url: string) => {
  try {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }

    const parsedUrl = new URL(url);

    if (!["http:", "https:"].includes(parsedUrl.protocol)) {
      return false;
    }

    const parsed = parse(parsedUrl.hostname);

    if (!parsed.isIcann) {
      return false;
    }

    return true;
  } catch (e) {
    console.log("parsedUrl error", e);
    return false;
  }
};

export const isEmptySlate = (value: Descendant[]) => {
  return convertSlateToText(value).text.trim().length === 0;
};
