import campaignService from "@/services/campaign.service";
import { useQuery } from "@tanstack/react-query";

const useInboxList = (id: number) => {
  return useQuery({
    queryKey: ["inboxList", id],
    queryFn: async () => campaignService.getInboxDetails(id),
  });
};

export default useInboxList;
