import { useMutation } from "@tanstack/react-query";
import campaignService from "@/services/campaign.service";

const useCreateCampaign = () => {
  return useMutation({
    mutationFn: async ({ name }: { name: string }) =>
      campaignService.createCampaign(name),
  });
};

export default useCreateCampaign;
