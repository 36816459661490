import MakeAPICall, { APIResponse } from "@/lib/apiCalls";
import {
  ModifyCampaignEmailAccountsPayload,
  PaginationPayload,
} from "@/types/campaings.types";

class CampaignService {
  constructor(private apiCaller = MakeAPICall) {}

  async getCampaigns<ResponseT>() {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/all-campaigns`,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async createCampaign<ResponseT>(name: string) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/create-campaign`,
      payload: { name },
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async deleteCampaign<ResponseT>(id: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/delete-campaign/${id}`,
    });
    const response = await apiCall.delete<ResponseT>();
    return response;
  }

  async getCampaignDetails<ResponseT>(id: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/get-campaign/${id}`,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async getCampaignLeads<ResponseT>(id: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/all-leads-by-campaign-id/${id}`,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async getCampaignAnalytics<ResponseT>(
    id: number,
    startDate?: string,
    endDate?: string,
  ) {
    // Construct query parameters
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("start_date", startDate);
    if (endDate) queryParams.append("end_date", endDate);

    // Append query parameters to the API path
    const apiPath = `campaign/analytics-by-campaign-id/${id}${queryParams.toString() ? `?${queryParams.toString()}` : ""}`;

    // Make the API call
    const apiCall = new this.apiCaller({ apiPath });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async deleteLead<ResponseT>(campaignId: number, leadId: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/delete-lead-by-campaign-id`,
      payload: { campaignId, leadId },
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async scheduleCampaign<ResponseT>(payload: {
    campaignId: number;
    timezone: string;
    daysOfTheWeek: number[];
    startHour: string;
    endHour: string;
    minTimeBtwEmails: number;
    newLeadsPerDay: number;
    startTime: string;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/schedule-campaign`,
      payload,
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async updateCampaign<ResponseT>(id: number, status: string) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/update-campaign`,
      payload: { campaignId: id, status },
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async updateCampaignDetails<ResponseT>(payload: {
    campaignId: number;
    leadPreferences?: any;
    personalizationPrompt?: string[];
    coachingPoints?: string[];
    proofPoints?: string[];
    painPoints?: any[];
    showSequencesScreen?: boolean;
    senderAccounts: any[];
  }) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/update-campaign-details`,
      payload,
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async saveSequence<ResponseT>(id: number, sequences: any) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/save-sequence`,
      payload: { campaignId: id, sequences },
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async getSequenceDetails<ResponseT>(id: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/get-sequence-by-campaign-id/${id}`,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async getInboxDetails<ResponseT>(id: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/inbox-details/${id}`,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async uploadLeads<ResponseT>(payload: FormData) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/add-leads-to-campaign`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async generateEmailWithAI<ResponseT>(campaignId: number) {
    const apiCall = new this.apiCaller({
      apiPath: `campaign/generate-email-template`,
      payload: { campaignId },
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  // Get all email accounts with pagination
  async getAllEmailAccounts<ResponseT>(
    payload: PaginationPayload,
  ): Promise<APIResponse<ResponseT>> {
    console.log(payload);
    const apiCall = new this.apiCaller({
      apiPath: `account-management/email-accounts?offset=${payload.offset}&limit=${payload.limit}`,
      payload,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  // Add email accounts to a campaign
  async addEmailAccountToCampaign<ResponseT>(
    campaignId: number,
    emailAccountIds: number[],
  ): Promise<APIResponse<ResponseT>> {
    const payload: ModifyCampaignEmailAccountsPayload = {
      email_account_ids: emailAccountIds,
    };
    const apiCall = new this.apiCaller({
      apiPath: `account-management/campaigns/${campaignId}/email-accounts`,
      payload,
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  // Remove email accounts from a campaign
  async removeEmailAccountFromCampaign<ResponseT>(
    campaignId: number,
    emailAccountIds: number[],
  ): Promise<APIResponse<ResponseT>> {
    const payload: ModifyCampaignEmailAccountsPayload = {
      email_account_ids: emailAccountIds,
    };

    const apiCall = new this.apiCaller({
      apiPath: `account-management/campaigns/${campaignId}/email-accounts`,
      payload,
    });
    const response = await apiCall.patch<ResponseT>();
    return response;
  }

  async getUserCampaignDetails(email: string) {
    const apiCall = new this.apiCaller({
      apiPath: "campaign/get-user-campaigns",
      payload: { email },
    });
    const response = await apiCall.post();
    return response;
  }
}

const campaignService = new CampaignService();

export default campaignService;
