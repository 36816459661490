import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import Icons from "../../Icons";
import { userStore } from "@/stores/user.store";

const CustomSelection = ({
  showLabel = false,
}: {
  showLabel?: "min" | "full" | false;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const totalLeadsCount = searchLeadsResult?.profiles.length || 1000;
  const selectedLeadsCount = selectedLeads.length;
  const [rangeSelectionCount, setRangeSelectionCount] = useState<number>(
    () => selectionCount || 0,
  );
  const handleApplyClick = () => {
    updateLeadState({
      selectionAction: "range-selection",
      selectionCount: rangeSelectionCount,
    });
    onClose();
  };

  const items = [
    {
      key: "key1",
      label: (
        <MenuItem
          className="border-y-1 !px-4"
          style={{ borderBottom: "2px solid #F0EFF1" }}
        >
          <div
            className="w-full py-1"
            onClick={() => {
              updateLeadState({
                selectionAction: "select-page",
              });
            }}
          >
            <span className="flex-1 text-sm font-medium">Select this page</span>
          </div>
        </MenuItem>
      ),
    },
    {
      key: "key2",
      label: (
        <MenuItem
          className="!px-4"
          style={{ borderBottom: "2px solid #F0EFF1" }}
        >
          <div
            className="w-full py-1"
            onClick={() => {
              onClose();
              updateLeadState({
                selectionAction: "select-all",
              });
            }}
          >
            <span className="flex-1 text-sm font-medium">
              Select all people
            </span>
          </div>
        </MenuItem>
      ),
    },
    {
      key: "key3",
      label: (
        <MenuItem
          className="!px-0 hover:!bg-white"
          style={{ borderBottom: "2px solid #F0EFF1" }}
        >
          <Accordion
            className="w-full"
            defaultIndex={[0]}
            allowMultiple
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AccordionItem border={"none"}>
              <AccordionButton className="hover:!bg-white">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className="text-sm font-medium"
                >
                  Advance Selection
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <div className="flex flex-col gap-2">
                  <div className="flex w-full cursor-default items-center gap-4 py-1">
                    <span className="flex-1 text-sm font-bold">
                      Select number of people
                    </span>
                    <Input
                      width={20}
                      size={"sm"}
                      value={rangeSelectionCount}
                      onChange={(e) => {
                        const val = e.target.value;
                        const numberVal = Number(val);
                        if (!isNaN(numberVal)) {
                          setRangeSelectionCount(numberVal);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <p className="text-xs text-darkTextGray">
                      {planType?.toLocaleLowerCase() === "free" ? (
                        <>
                          <sup>*</sup> Free users can export max 25 leads. For
                          more leads please upgrade.
                        </>
                      ) : (
                        <>
                          <sup>*</sup>Paid users can export max 1000 leads. For
                          more leads please contact us.
                        </>
                      )}
                    </p>
                  </div>
                  <Button
                    size={"sm"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
                    isDisabled={
                      !rangeSelectionCount || rangeSelectionCount == 0
                    }
                    onClick={handleApplyClick}
                  >
                    Apply Selection
                  </Button>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </MenuItem>
      ),
    },
    {
      key: "key4",
      label: (
        <MenuItem className="!px-4">
          <div
            className="w-full py-1"
            onClick={() => {
              updateLeadState({
                selectionAction: "clear-all",
              });
            }}
          >
            <span className="flex-1 text-sm font-medium">Clear selection</span>
          </div>
        </MenuItem>
      ),
    },
  ];

  const getSelectionText = (extended = false) => {
    if (
      selectionAction === "select-all" &&
      planType?.toLocaleLowerCase() !== "free"
    ) {
      return extended ? "All Selected" : "All";
    }
    if (selectionCount && planType?.toLocaleLowerCase() !== "free") {
      return `${selectionCount} Selected`;
    }
    if (selectedLeads.length) {
      return `${selectedLeads.length} selected`;
    }
    return "";
  };

  console.log("myLog selectionAction", selectionAction);

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <Tooltip label={getSelectionText(true)}>
        <MenuButton
          as={Button}
          className="joyride-select-leads relative !h-[35px] text-clip !text-[#5024DC] hover:!bg-[transparent]"
          onClick={onOpen}
        >
          <div className="!flex !items-center gap-1">
            <Checkbox
              sx={{
                "& .chakra-checkbox__control": {
                  background: selectedLeads.length ? "#5024DC" : "#fff",
                },
              }}
              isChecked={selectedLeadsCount === totalLeadsCount}
              isIndeterminate={
                selectedLeadsCount > 0 && selectedLeadsCount < totalLeadsCount
              }
            />
            {showLabel && (
              <p
                className="font-[500]] relative text-[14px] text-[#5024DC]"
                key={Math.random()}
              >
                {getSelectionText(showLabel === "full")}
              </p>
            )}

            <Icons.ArrowDownIcon />
          </div>
        </MenuButton>
      </Tooltip>
      <Portal>
        <MenuList
          className="!min-w-[400px] !max-w-[400px] !bg-white !p-0"
          zIndex={999999}
        >
          {items.map((item) => (
            <Fragment key={item.key}>
              {React.cloneElement(item.label, { onClose })}
            </Fragment>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default CustomSelection;
