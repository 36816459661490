import { queryClient } from "@/lib/queryClient";
import campaignService from "@/services/campaign.service";
import { useCampaignStore } from "@/stores/campaigns.store";
import { Box, Switch, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import WebsiteScrapeIcon from "@/assets/svgs/outreach-website-scrape.svg";
import XPostsIcon from "@/assets/svgs/outreach-x-posts.svg";
import LinkedinPostsIcon from "@/assets/svgs/outreach-linkedin-posts.svg";
import PressReleaseIcon from "@/assets/svgs/outreach-press-release.svg";
import FundingAnnouncementIcon from "@/assets/svgs/outreach-funding-announcement.svg";
import MutualSchoolIcon from "@/assets/svgs/outreach-mutual-school.svg";
import MutualJobIcon from "@/assets/svgs/outreach-mutual-job.svg";
import MutualCityIcon from "@/assets/svgs/outreach-mutual-city.svg";

const Outreach = () => {
  const [personalizationOptions, setPersonalizationOptions] = useState([
    {
      label: "Website Scrape",
      value: "website_scrape",
      description: "Analyze the leads website for product updates",
      icon: WebsiteScrapeIcon,
      isSelected: false,
    },
    {
      label: "X posts",
      value: "x_posts",
      description: "Highlight recent X posts published by prospects",
      icon: XPostsIcon,
      isSelected: false,
    },
    {
      label: "LinkedIn Posts",
      value: "linkedIn_posts",
      description: "Feature recent LinkedIn updates shared by leads",
      icon: LinkedinPostsIcon,
      isSelected: false,
    },
    {
      label: "Press Releases",
      value: "press_releases",
      description: "Metion notable press coverage about prospects",
      icon: PressReleaseIcon,
      isSelected: false,
    },
    {
      label: "Funding Announcements",
      value: "funding_announcements",
      description: "Compliment prospects on recent funding news",
      icon: FundingAnnouncementIcon,
      isSelected: false,
    },
    {
      label: "Mutual Previous School",
      value: "mutual_previous_school",
      description: "Mention that you attended the same university",
      icon: MutualSchoolIcon,
      isSelected: false,
    },
    {
      label: "Mutual Previous Job",
      value: "mutual_previous_job",
      description: "Highlight that you worked in the same company",
      icon: MutualJobIcon,
      isSelected: false,
    },
    {
      label: "Mutual City",
      value: "mutual_city",
      description: "Establish common grounds by referencing the city",
      icon: MutualCityIcon,
      isSelected: false,
    },
  ]);

  const campaignDetails = useCampaignStore((state) => state.campaignDetails);
  const selectedCampaign = useCampaignStore((state) => state.selectedCampaign);

  const { mutateAsync: campaignDetailsMutateAsync } = useMutation({
    mutationFn: (payload: any) =>
      campaignService.updateCampaignDetails(payload),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["campaignDetails"] });
    },
  });

  // Handle switch change
  const handleSwitchChange = (index: number, isChecked: boolean) => {
    const updatedOptions = personalizationOptions.map((option, idx) => {
      if (idx === index) {
        return { ...option, isSelected: isChecked };
      }
      return option;
    });

    setPersonalizationOptions(updatedOptions);

    // Extract selected values
    const selectedValues = updatedOptions
      .filter((option) => option.isSelected)
      .map((option) => option.value);

    // Call mutation function
    campaignDetailsMutateAsync({
      campaignId: selectedCampaign,
      personalizationPrompt: selectedValues,
    });
  };

  // Sync state with campaignDetails on mount/update
  useEffect(() => {
    if (campaignDetails?.personalizationPrompt?.length) {
      const updatedOptions = personalizationOptions.map((option) => ({
        ...option,
        isSelected: campaignDetails.personalizationPrompt.includes(
          option.value,
        ),
      }));

      setPersonalizationOptions(updatedOptions);
    }
  }, [campaignDetails]);

  return (
    <div className="flex justify-center p-[32px]">
      <Box className="w-[60%] shadow-md border border-[#F0EFF1] p-[24px] rounded-md">
        <p className="text-[20px] font-[600] text-[#14171F]">
          Personalization Sources
        </p>
        <p className="text-[14px] font-[400] text-[#7E889F]">
          Select which personalization sources you want Nia to use in your
          outbound emails.
        </p>
        <VStack spacing={4} align={"stretch"} className="mt-4">
          {personalizationOptions.map((item, index) => (
            <div
              key={item.value}
              className="bg-[#F5F3FF] border border-[#D0D4DC] p-[12px] rounded-[10px] flex items-center justify-between"
            >
              <div className="flex items-center gap-3">
                <img src={item.icon} alt="" height={32} width={32} />
                <div>
                  <p className="text-[#0F0D1C] font-medium text-[14px]">
                    {item.label}
                  </p>
                  <p className="text-[#7E889F] font-regular text-[13px]">
                    {item.description}
                  </p>
                </div>
              </div>
              <Switch
                isChecked={item.isSelected}
                size="md"
                onChange={(e) => handleSwitchChange(index, e.target.checked)}
                id={`source-switch-${item.value}`}
                className="text-[14px] flex font-medium"
                colorScheme="purple"
              />
            </div>
          ))}
        </VStack>
      </Box>
    </div>
  );
};

export default Outreach;
