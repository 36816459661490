import CampaignDetails from "@/components/CampaignDetails/CampaignDetails";
import { FileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = new FileRoute("/campaigns/$campaignId").createRoute({
  parseParams: (params) => ({
    campaignId: z.number().parse(Number(params.campaignId)),
  }),
  component: CampaignDetailsPage,
});

function CampaignDetailsPage() {
  const params = Route.useParams();

  return <CampaignDetails campaignId={params.campaignId} />;
}
