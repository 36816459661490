import CampaignsManager from "@/components/Campaigns/CampaignsManager";
import { userStore } from "@/stores/user.store";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/campaigns/").createRoute({
  component: CampaignsPage,
  errorComponent: () => <div>Error</div>,
});

function CampaignsPage() {
  const currentUser = userStore((state) => state.currentUser);
  const isAdmin = currentUser.role === "admin";

  if (!isAdmin) {
    return <div>Not Found</div>;
  }
  return <CampaignsManager />;
}

export default CampaignsPage;
