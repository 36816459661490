/* eslint-disable react-hooks/exhaustive-deps */
import {
  ColDef,
  RowClassRules,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import NoResult from "../Common/NoResult";
import ResultScreenLoader from "../Common/ResultScreenLoader";
import { CompanyDetailsRenderer } from "./CompanyDetailsRendere";
import { UserDetailsRenderer } from "./UserDetailsRenderer";
import { WorkEmailRenderer } from "./WorkEmailRenderer";
import PricingPopup from "../Common/PricingPopup/PricingPopup";
import Pagination from "./Pagination";
import CustomSelection from "./components/CustomSelection";
import BeginSearch from "../Common/BeginSearch";
import PeopleSearchResultTableActions from "./PeopleSearchResultTableActions";

import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";
import useSocket from "@/lib/socket";
import { userStore } from "@/stores/user.store";

import { ISocketSingleEmailData } from "@/types/socket.types";
import Icons from "../Icons";

type PeopleSearchResultTableProps = {
  uniqueSocketId: string;
  isForAISDR?: boolean;
  handleAISDR?: () => void;
};

const PeopleSearchResultTable = ({
  uniqueSocketId,
  isForAISDR = false,
  handleAISDR,
}: PeopleSearchResultTableProps) => {
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.currentSearchPayload,
  );
  const [showPopup, setShowPopup] = useState(false); // State for showing/hiding popup
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  console.log("myLog planType", planType);

  const gridRef = useRef<AgGridReact>(null);
  const [formattedProfilesData, setFormattedProfilesData] = useState<any[]>([]);
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const updateState = useSearchLeadsStore((state) => state.updateState);
  const updateSelectedLeads = useSearchLeadsStore(
    (state) => state.updateSelectedLeads,
  );
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const searchingFor = useSearchLeadsStore((state) => state.searchingFor);
  const { subscribe } = useSocket();

  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: "",
        checkboxSelection: true,
        pinned: "left",
        headerCheckboxSelection: false,
        headerComponent: CustomSelection,
        width: 80,
      },
      {
        field: "name",
        headerName: "Name",
        cellRenderer: UserDetailsRenderer,
        pinned: true,
        minWidth: 250,
      },
      {
        field: "title",
        headerName: "Title",
        minWidth: 200,
      },
      {
        field: "workEmail",
        headerName: "Work Email",
        cellRenderer: WorkEmailRenderer,
        minWidth: 200,
        maxWidth: 250,
        hide: isForAISDR && true,
      },
      {
        field: "companyName",
        headerName: "Company",
        cellRenderer: CompanyDetailsRenderer,
        minWidth: 200,
      },
      {
        field: "location",
        headerName: "Location",
        minWidth: 250,
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      cellStyle: {
        borderRight: "1px solid #e2e8f0",
        display: "flex",
        alignItems: "center",
      },
      editable: false,
      cellDataType: false,
    };
  }, []);

  const rowClassRules: RowClassRules =
    planType?.toLocaleLowerCase() === "free"
      ? {
          "blurred-row": (params) => params.rowIndex > 24, // Apply class to rows after index 24
        }
      : {};

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const selectedRows = event.api.getSelectedRows();
      console.log("myDebug selectedRows", selectedRows);
      updateSelectedLeads(selectedRows);
    },
    [updateSelectedLeads],
  );

  const formatData = (profiles: any) => {
    const formattedData = profiles?.map((item: any) => {
      return {
        _id: item._id || "",
        id: item.id || "",
        name: item?.name || "",
        linkedInUrl: item?.linkedin_url || "",
        title: item?.title || "",
        companyName: item?.organization?.name || "",
        companyWebsite: item?.organization?.website_url
          ? item?.organization?.website_url.startsWith("http://") ||
            item?.organization?.website_url.startsWith("https://")
            ? item?.organization?.website_url
            : `http://${item?.organization?.website_url}`
          : "",
        location:
          item?.location ||
          `${item?.state ? `${item?.state} ,` : ""} ${item?.country || ""}` ||
          "",
        companyLinkedInURL: item?.organization?.linkedin_url || "",
        employmentHistory:
          item?.employment_history?.length > 0
            ? `Worked With ${item?.employment_history?.length} Companies`
            : "",
        profilePhoto: item?.photo_url || "",
        headline: item?.headline || "",
        workEmail: item.workEmail || "",
        quickAction: { ...item, uniqueSocketId },
      };
    });
    setFormattedProfilesData(formattedData);
  };

  useEffect(() => {
    if (searchLeadsResult && searchLeadsResult?.profiles?.length > 0) {
      formatData(searchLeadsResult?.profiles);
    }
  }, [searchLeadsResult]);

  useEffect(() => {
    const maxSelectableRows =
      planType?.toLocaleLowerCase() === "free" ? 25 : Infinity;

    gridRef.current?.api?.forEachNode((row) => {
      gridRef.current?.api.getRowNode(row.id!)?.setSelected(false);
    });

    if (selectionAction === "select-all") {
      if (planType?.toLocaleLowerCase() === "free") {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (index < maxSelectableRows) {
            gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          }
        });
      } else {
        gridRef.current?.api?.forEachNode((row) => {
          gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
        });
      }
    } else if (selectionAction === "clear-all") {
      gridRef.current?.api?.forEachNode((row) => {
        gridRef.current?.api.getRowNode(row.id!)?.setSelected(false);
      });
      updateState({
        selectionCount: 0,
      });
    } else if (selectionAction === "select-page") {
      const currentPage = gridRef.current?.api.paginationGetCurrentPage() || 0;
      const pageSize = gridRef.current?.api.paginationGetPageSize() || 100;
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      if (planType?.toLocaleLowerCase() === "free") {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (
            index >= startRow &&
            index < endRow &&
            index < maxSelectableRows
          ) {
            gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          }
        });
      } else {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (index >= startRow && index < endRow) {
            gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          }
        });
      }
    } else if (selectionAction === "range-selection") {
      const currentPage = gridRef.current?.api.paginationGetCurrentPage() || 0;
      const pageSize = gridRef.current?.api.paginationGetPageSize() || 100;
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      const rowsToSelect =
        planType?.toLocaleLowerCase() === "free"
          ? Math.min(selectionCount, maxSelectableRows)
          : Math.min(selectionCount, pageSize);
      let selectedCount = 0;

      gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
        if (
          index >= startRow &&
          index < endRow &&
          selectedCount < rowsToSelect
        ) {
          gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          selectedCount++;
        }
      });
    }
  }, [selectionAction, selectionCount, planType]);

  const onBodyScroll = useCallback(() => {
    if (planType?.toLocaleLowerCase() === "free") {
      const rowIndexToWatch = 25;
      const rowNode =
        gridRef.current?.api.getDisplayedRowAtIndex(rowIndexToWatch);

      if (rowNode) {
        const rowElement = document.querySelector(
          `.ag-center-cols-container [row-index='${rowIndexToWatch}']`,
        );

        const gridBody = document.querySelector(".ag-body-viewport");
        if (rowElement && gridBody) {
          const rowRect = rowElement.getBoundingClientRect();
          const gridRect = gridBody.getBoundingClientRect();

          if (rowRect.top <= gridRect.top) {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const api = gridRef.current?.api;
    if (api) {
      api.addEventListener("bodyScroll", onBodyScroll);
      return () => api.removeEventListener("bodyScroll", onBodyScroll);
    }
  }, [onBodyScroll]);

  useEffect(() => {
    subscribe(uniqueSocketId, (data: ISocketSingleEmailData) => {
      const rowId = data?.bodyData?.payload?.rowId || "";
      if (!rowId) return;

      const rowData = formattedProfilesData.find(
        (profileData) => profileData.id === rowId,
      );
      if (rowData) {
        rowData.workEmail = data.response.email;

        const workEmailCol = gridRef.current?.api?.getColumn("workEmail");
        const currentWidth = workEmailCol?.getActualWidth() || 200;
        const newWidth = Math.max(
          currentWidth,
          data.response.email.length * 10 + 80,
        );
        // workEmailCol?.setActualWidth(newWidth, "uiColumnResized");
        gridRef.current?.api.setColumnWidths(
          [
            {
              key: "workEmail",
              newWidth,
            },
          ],
          true,
          "uiColumnResized",
        );

        gridRef.current?.api?.applyTransactionAsync({
          update: [rowData],
        });
        gridRef.current?.api?.resetColumnState();
      }
    });
  }, [uniqueSocketId, formattedProfilesData, subscribe]);

  if (searchingFor === "PEOPLE") {
    return <ResultScreenLoader />;
  }

  if (!searchLeadsResult?.profiles) {
    return <BeginSearch />;
  }

  if (searchLeadsResult?.profiles?.length === 0) {
    return <NoResult />;
  }

  return (
    <>
      <div className="my-2 flex items-center gap-[12px]">
        <CustomSelection />
        {!isForAISDR ? (
          <>
            <PeopleSearchResultTableActions
              buttonIcon={<Icons.BsMagic />}
              buttonText={"Add to Persana Table & Enrich"}
              currentSearchPayload={currentSearchPayload}
              isForFindEmail={false}
              variant="secondary"
            />
            <PeopleSearchResultTableActions
              buttonIcon={<Icons.SearchBarButton />}
              buttonText={"Export Emails"}
              currentSearchPayload={currentSearchPayload}
              isForFindEmail={true}
              variant="primary"
            />
          </>
        ) : (
          <PeopleSearchResultTableActions
            buttonIcon={<Icons.BsMagic />}
            buttonText={"Add Leads to Campaign"}
            currentSearchPayload={currentSearchPayload}
            isForFindEmail={false}
            isForAISDR
            handleAISDR={handleAISDR}
            variant="secondary"
          />
        )}
      </div>
      <div className="relative flex size-full flex-col justify-between">
        <div
          className="ag-theme-quartz custom-ag-grid relative"
          style={{
            width: "100%",
            height: "68vh",
          }}
        >
          {showPopup && planType?.toLocaleLowerCase() === "free" && (
            <PricingPopup setShowPopup={setShowPopup} />
          )}
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={formattedProfilesData}
            defaultColDef={defaultColDef}
            rowClassRules={rowClassRules}
            headerHeight={40}
            rowHeight={50}
            rowClass="border-1 border-gray-200"
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressColumnMoveAnimation
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
            pagination={false}
            autoSizeStrategy={{
              type: "fitCellContents",
            }}
            onBodyScroll={onBodyScroll}
          />
          <Pagination
            paginationData={searchLeadsResult.pagination}
            className="mb-3 rounded-b-[6px] bg-[#f7f7f7] px-4 py-2"
          />
        </div>
      </div>
    </>
  );
};

export default PeopleSearchResultTable;
