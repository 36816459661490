import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

import { Box, Text } from "@chakra-ui/react";
import Icons from "@/components/Icons";

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const CampaignReach = ({
  campaignAnalytics,
  dates,
}: {
  campaignAnalytics: any;
  dates: string[];
}) => {
  if (!campaignAnalytics) {
    return <div>Loading...</div>;
  }

  const {
    sent_count = 0,
    open_count = 0,
    click_count = 0,
    reply_count = 0,
    bounce_count = 0,
  } = campaignAnalytics || {};

  // Create dummy data for each date (replace this with real data if available per date)
  const categories = ["Sent", "Opened", "Clicked", "Replied", "Bounced"];
  const values = [
    parseInt(sent_count, 10),
    parseInt(open_count, 10),
    parseInt(click_count, 10),
    parseInt(reply_count, 10),
    parseInt(bounce_count, 10),
  ];

  // Assign the same values for every date for simplicity
  const datasets = categories.map((category, index) => ({
    label: category,
    data: dates.map(() => values[index]),
    backgroundColor: ["#5C34DD", "#C03EDC", "#BD90F4", "#DDD8FF", "#F5F3FF"][
      index
    ],
    borderColor: ["#5C34DD", "#C03EDC", "#BD90F4", "#DDD8FF", "#F5F3FF"][index],
    borderWidth: 1,
  }));

  // Chart data configuration
  const data = {
    labels: dates,
    datasets,
  };

  // Chart options configuration
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderRadius: 4,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true },
    },
  };

  return (
    <Box
      className="bg-white rounded-lg p-4 border-[#F0EFF1] !shadow-lg"
      style={{ width: "60%" }}
    >
      <div className="flex items-center gap-2">
        <Icons.InformationIcon className="text-[20px] text-[#5024DC]" />
        <Text className="text-[16px] text-[#373F51] font-medium">
          Campaign Reach
        </Text>
      </div>

      <Box className="flex justify-center items-center !w-full mt-4 relative h-[350px]">
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default CampaignReach;
