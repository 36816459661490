import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import Settings from "./Settings";
import { userStore } from "@/stores/user.store";
import { useEffect, useState } from "react";
import PainPoints from "./PainPoints";
import ProofPoints from "./ProofPoints";
import CoachingPoints from "./CoachingPoints";
import { useCampaignStore } from "@/stores/campaigns.store";
import { useMutation } from "@tanstack/react-query";
import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";

export interface Feature {
  point: string;
  solution: string;
}

const Pitch = () => {
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const [profileInfo, setProfileInfo] = useState(() => ({
    firstName: currentUser?.profileInfo?.firstName || "",
    companyName: currentUser?.profileInfo?.companyName || "",
    companyDescription: currentUser?.profileInfo?.companyDescription || "",
    callToAction: currentUser?.profileInfo?.callToAction || "",
  }));
  const [coachingPoints, setCoachingPoints] = useState<string[]>([]);
  const [proofPoints, setProofPoints] = useState<string[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);

  const campaignDetails = useCampaignStore((state) => state.campaignDetails);
  const selectedCampaign = useCampaignStore((state) => state.selectedCampaign);
  const { mutateAsync: campaignDetailsMutateAsync, isPending } = useMutation({
    mutationFn: (payload: any) =>
      campaignService.updateCampaignDetails(payload),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["campaignDetails"] });
    },
  });

  const handleFeatureInputChange = (
    index: number,
    field: keyof Feature,
    value: string,
  ) => {
    setFeatures((prevFeatures) => {
      const updatedFeatures = [...prevFeatures];
      updatedFeatures[index] = { ...updatedFeatures[index], [field]: value };
      return updatedFeatures;
    });
  };

  const handleAddFeature = () => {
    setFeatures((prevFeatures) => [
      ...prevFeatures,
      { point: "", solution: "" },
    ]);
  };

  const handleDeleteFeature = (index: number) => {
    setFeatures((prevFeatures) => prevFeatures.filter((_, i) => i !== index));
  };

  const handleProofPointInputChange = (index: number, value: string) => {
    setProofPoints((prevPoints) => {
      const updatedPoints = [...prevPoints];
      updatedPoints[index] = value;
      return updatedPoints;
    });
  };

  const handleAddProofPoint = () => {
    setProofPoints((prevPoints) => [...prevPoints, ""]);
  };

  const handleDeleteProofPoint = (index: number) => {
    setProofPoints((prevPoints) => prevPoints.filter((_, i) => i !== index));
  };

  const handleUpdateSettings = (fieldName: string, value: string) => {
    setProfileInfo({
      ...profileInfo,
      [fieldName]: value,
    });
  };

  const handleCoachingInputChange = (index: number, value: string) => {
    setCoachingPoints((prevPoints) => {
      const updatedPoints = [...prevPoints];
      updatedPoints[index] = value;
      return updatedPoints;
    });
  };

  const handleAddCoachingPoint = () => {
    setCoachingPoints((prevPoints) => [...prevPoints, ""]);
  };

  const handleDeleteCoachingPoint = (index: number) => {
    setCoachingPoints((prevPoints) => prevPoints.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const res = await campaignDetailsMutateAsync({
      campaignId: selectedCampaign,
      proofPoints,
      painPoints: features,
      coachingPoints,
    });

    if (res?.success) {
      toast({
        title: "Success",
        description: "Successfully updated campaign details",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Error",
        description: "failed to update campaign details",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (campaignDetails?.proofPoints) {
      setProofPoints(campaignDetails?.proofPoints);
    }
    if (campaignDetails?.painPoints) {
      setFeatures(campaignDetails?.painPoints);
    }
    if (campaignDetails?.coachingPoints) {
      setCoachingPoints(campaignDetails?.coachingPoints);
    }
  }, [campaignDetails]);

  return (
    <div className="flex flex-col gap-2 items-center justify-center">
      <div className="flex items-center justify-center w-full !bg-[#FDFCFF] py-[40px] border border-[#F0EFF1]">
        <Box className="shadow-md w-[80%] border border-[#C03EDC] p-[32px] rounded-md bg-white">
          <Settings
            handleUpdateSettings={handleUpdateSettings}
            profileInfo={profileInfo}
          />
        </Box>
      </div>
      <Box className="shadow-md w-[80%] border border-[#D0D4DC] p-[32px] rounded-md mt-3">
        <ProofPoints
          proofPoints={proofPoints}
          handleAddProofPoint={handleAddProofPoint}
          handleDeleteProofPoint={handleDeleteProofPoint}
          handleInputChange={handleProofPointInputChange}
        />
        <Divider className="my-6" />
        <PainPoints
          features={features}
          handleAddFeature={handleAddFeature}
          handleDeleteFeature={handleDeleteFeature}
          handleInputChange={handleFeatureInputChange}
        />
        <Divider className="my-6" />
        <CoachingPoints
          coachingPoints={coachingPoints}
          handleAddCoachingPoint={handleAddCoachingPoint}
          handleDeleteCoachingPoint={handleDeleteCoachingPoint}
          handleInputChange={handleCoachingInputChange}
        />
      </Box>

      <Button
        className="w-fit my-2"
        size={"md"}
        colorScheme="purple"
        onClick={handleSave}
        isLoading={isPending}
      >
        Save Details
      </Button>
    </div>
  );
};

export default Pitch;
