import { useEffect, useState } from "react";

import Icons from "../Icons";
import { twMerge } from "tailwind-merge";

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  titleClassName?: string;
  iconClassName?: string;
  isDefaultOpen?: boolean;
  isOpen?: boolean;
  description?: string | React.ReactNode;
  avoidRootClassName?: boolean;
  isCompleted?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const CustomAccordion = ({
  title,
  children,
  titleClassName = "",
  iconClassName = "",
  isDefaultOpen = false,
  isOpen,
  description,
  avoidRootClassName = false,
  isCompleted = false,
  isDisabled = false,
  isLoading = false,
}: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(isDefaultOpen);
  useEffect(() => {
    if (isLoading) return setAccordionOpen(false);

    if (typeof isDefaultOpen === "boolean")
      return setAccordionOpen(isDefaultOpen);
    if (typeof isOpen === "boolean") setAccordionOpen(isOpen);
  }, [isOpen ?? null, isLoading, isDefaultOpen]);
  return (
    <div className={avoidRootClassName ? "" : "mt-4"}>
      <button
        className={twMerge(
          `flex w-full flex-row items-center space-x-1 rounded-md text-left font-semibold text-[0.9rem]`,
          titleClassName,
        )}
        onClick={(e) => {
          if (typeof isOpen === "boolean") return;
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}
        aria-expanded={accordionOpen}
        aria-controls={`accordion-text-01`}
        disabled={isDisabled || isLoading}
      >
        <Icons.CircleArrowIcon
          className={`${twMerge(
            "shrink-0 origin-center fill-primaryPink text-base transition duration-200 ease-out",
            iconClassName,
          )} ${!accordionOpen ? "-rotate-90" : ""}`}
        />
        <span className={isCompleted ? "" : "w-full"}>
          {title}
          <small className="ml-2 font-thin">{description}</small>
        </span>

        {isCompleted && (
          <Icons.CircleCheck className="shrink-0 origin-center fill-green-600 text-base" />
        )}
      </button>
      <div
        id={`accordion-text-01`}
        role="region"
        aria-labelledby={`accordion-title-01`}
        className={`${
          accordionOpen ? "block" : "hidden"
        } text-gray-500 mt-2 text-sm`}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CustomAccordion;
