import { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ColDef, GridApi } from "ag-grid-community";

import Icons from "../Icons";
import useLeadList from "./hooks/useLeadList";
import Swal from "sweetalert2";
import useDeleteLead from "./hooks/useDeleteLead";
import LeadStatus from "../Common/Campaigns/LeadStatus";

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

const AllLeadsGrid = ({ campaignId }: { campaignId: number }) => {
  const toast = useToast();
  const {
    data: allLeads,
    isFetching,
    isLoading,
    refetch: refetchAllLeads,
  } = useLeadList(campaignId) as any;
  const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
  const deleteLeadMutation = useDeleteLead();

  const handleDelete = (id: number) => {
    Swal.fire({
      text: "Do you want to delete the lead?",
      icon: "warning",
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      denyButtonText: "No",
      showCloseButton: true,
      preConfirm: async () => {
        deleteLeadMutation.mutate(
          { campaignId, leadId: id },
          {
            onSuccess: (result) => {
              if (result?.success) {
                toast({
                  title: "Lead deleted successfully",
                  status: "success",
                  duration: 2000,
                  position: "top-right",
                });
              } else {
                toast({
                  title: "Error deleting Lead",
                  status: "error",
                  duration: 2000,
                  position: "top-right",
                });
              }
            },

            onError: () => {
              toast({
                title: "Error deleting Lead",
                status: "error",
                duration: 2000,
                position: "top-right",
              });
            },
          },
        );
      },
      preDeny: async () => {
        return;
      },
    });
  };

  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: "Name",
          field: "first_name",
          sortable: true,
          width: 300,
          cellRenderer: (params: any) => (
            <p>
              {`${params.data.lead.first_name || ""}  ${
                params.data.lead.last_name || ""
              }`}
            </p>
          ),
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 300,
          cellRenderer: (params: any) => (
            <p className="text-[#5024DC]">{params.data.lead.email}</p>
          ),
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          width: 250,
          cellRenderer: (params: any) => (
            <LeadStatus status={params.data.status} />
          ),
        },
        {
          headerName: "Actions",
          field: "",
          sortable: true,
          cellRenderer: (params: any) => (
            <>
              <IconButton
                className="mr-2"
                size="xs"
                bg={"#F5F3FF"}
                color={"#7E889F"}
                variant="outline"
                icon={<Icons.DeleteIcon />}
                isLoading={deleteLeadMutation.isPending}
                onClick={() => handleDelete(params?.data?.lead?.id)}
                aria-label={"Delete Lead"}
              />
            </>
          ),
        },
      ] as ColDef<any>[],
    [],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (isFetching) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    if (!allLeads?.data?.data?.length) {
      gridApi?.showNoRowsOverlay();
    }
  }, [isFetching, gridApi, allLeads?.data?.data ?? null]);

  return (
    <Box className="ag-theme-quartz mx-4">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Box className="flex flex-row items-center justify-between mb-2">
            <></>
            <ButtonGroup size="sm" className="my-2 !ml-auto">
              <Button
                onClick={() => refetchAllLeads()}
                isLoading={isFetching}
                isDisabled={isFetching}
                variant={"outline"}
                aria-label={"Reload"}
                leftIcon={<Icons.RefreshIcon />}
              >
                Refetch Leads
              </Button>
            </ButtonGroup>
          </Box>
          <Box
            className="custom-scrollbar"
            overflow="auto"
            height="calc(100vh - 240px)"
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={allLeads?.data?.data ?? []}
              rowSelection="multiple"
              suppressRowClickSelection
              domLayout="normal"
              onGridReady={onGridReady}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AllLeadsGrid;
