import { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, Button, ButtonGroup, Spinner, Tooltip } from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ColDef, GridApi } from "ag-grid-community";

import useInboxList from "./hooks/useInboxList";
import { stripHtmlTags } from "@/lib/utils";
import Icons from "../Icons";

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

const AllInboxListGrid = ({ campaignId }: { campaignId: number }) => {
  const {
    data: allInboxMessages,
    isFetching,
    isLoading,
    refetch,
  } = useInboxList(campaignId) as any;
  const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);

  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: "Lead Name",
          field: "lead_name",
          sortable: true,
          width: 250,
          cellRenderer: (params: any) => <p>{params.data.lead_name || ""}</p>,
        },
        {
          headerName: "Lead Email",
          field: "lead_email",
          sortable: true,
          width: 250,
          cellRenderer: (params: any) => (
            <p className="text-[#5024DC]">{params.data.lead_email || ""}</p>
          ),
        },
        {
          headerName: "Message Sent",
          field: "email_message",
          sortable: true,
          width: 500,
          cellRenderer: (params: any) => (
            <Tooltip label={stripHtmlTags(params.data.email_message || "")}>
              <p>{stripHtmlTags(params.data.email_message || "")}</p>
            </Tooltip>
          ),
        },
        {
          headerName: "Sent On",
          field: "sent_time",
          sortable: true,
          width: 200,
          cellRenderer: (params: any) => (
            <Tooltip label={new Date(params.data.sent_time).toLocaleString()}>
              {dayjs(params.data.sent_time).fromNow()}
            </Tooltip>
          ),
        },
      ] as ColDef<any>[],
    [],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (isFetching) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    if (!allInboxMessages?.data?.data?.length) {
      gridApi?.showNoRowsOverlay();
    }
  }, [isFetching, gridApi, allInboxMessages?.data?.data ?? null]);

  return (
    <Box className="ag-theme-quartz mx-4">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Box className="flex flex-row items-center justify-between mb-2">
            <></>
            <ButtonGroup size="sm" className="my-2 !ml-auto">
              <Button
                onClick={() => refetch()}
                isLoading={isFetching}
                isDisabled={isFetching}
                variant={"outline"}
                aria-label={"Reload"}
                leftIcon={<Icons.RefreshIcon />}
              >
                Refetch Inbox Details
              </Button>
            </ButtonGroup>
          </Box>
          <Box
            className="custom-scrollbar"
            overflow="auto"
            height="calc(100vh - 180px)"
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={allInboxMessages?.data?.data ?? []}
              rowSelection="multiple"
              suppressRowClickSelection
              domLayout="normal"
              onGridReady={onGridReady}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AllInboxListGrid;
