import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type State = {
  selectedCampaign: number | null;
  campaignDetails: any;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export const useCampaignStore = create<State & Actions>((set) => ({
  selectedCampaign: null,
  campaignDetails: {},
  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Campaigns", useCampaignStore);
}
