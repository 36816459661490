import { APIResponse } from "@/lib/apiCalls";
import campaignService from "@/services/campaign.service";
import { EmailAccount, PaginationPayload } from "@/types/campaings.types";
import { useQuery } from "@tanstack/react-query";

export const useGetAllEmailAccounts = (payload: PaginationPayload) => {
  return useQuery<APIResponse<EmailAccount[]>>({
    queryKey: ["allEmailAccounts", payload],
    queryFn: async () =>
      campaignService.getAllEmailAccounts<EmailAccount[]>(payload),
    enabled: !!payload,
  });
};
