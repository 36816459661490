import campaignService from "@/services/campaign.service";
import { useQuery } from "@tanstack/react-query";

const useAnalytics = (id: number, startDate: string, endDate: string) => {
  return useQuery({
    queryKey: ["campaignAnalytics", id],
    queryFn: async () =>
      campaignService.getCampaignAnalytics(id, startDate, endDate),
  });
};

export default useAnalytics;
