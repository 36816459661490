export const timezones = [
  {
    value: "Etc/GMT+12",
    label: "Etc/GMT+12(UTC-12:00)",
    utc: "UTC-12:00",
    offset: -12,
  },
  {
    value: "Etc/GMT+11",
    label: "Etc/GMT+11(UTC-11:00)",
    utc: "UTC-11:00",
    offset: -11,
  },
  {
    value: "Pacific/Midway",
    label: "Pacific/Midway(UTC-11:00)",
    utc: "UTC-11:00",
    offset: -11,
  },
  {
    value: "Pacific/Niue",
    label: "Pacific/Niue(UTC-11:00)",
    utc: "UTC-11:00",
    offset: -11,
  },
  {
    value: "Pacific/Pago_Pago",
    label: "Pacific/Pago_Pago(UTC-11:00)",
    utc: "UTC-11:00",
    offset: -11,
  },
  {
    value: "Etc/GMT+10",
    label: "Etc/GMT+10(UTC-10:00)",
    utc: "UTC-10:00",
    offset: -10,
  },
  {
    value: "Pacific/Honolulu",
    label: "Pacific/Honolulu(UTC-10:00)",
    utc: "UTC-10:00",
    offset: -10,
  },
  {
    value: "Pacific/Johnston",
    label: "Pacific/Johnston(UTC-10:00)",
    utc: "UTC-10:00",
    offset: -10,
  },
  {
    value: "Pacific/Rarotonga",
    label: "Pacific/Rarotonga(UTC-10:00)",
    utc: "UTC-10:00",
    offset: -10,
  },
  {
    value: "Pacific/Tahiti",
    label: "Pacific/Tahiti(UTC-10:00)",
    utc: "UTC-10:00",
    offset: -10,
  },
  {
    value: "America/Anchorage",
    label: "America/Anchorage(UTC-09:00)",
    utc: "UTC-09:00",
    offset: -8,
  },
  {
    value: "America/Juneau",
    label: "America/Juneau(UTC-09:00)",
    utc: "UTC-09:00",
    offset: -8,
  },
  {
    value: "America/Nome",
    label: "America/Nome(UTC-09:00)",
    utc: "UTC-09:00",
    offset: -8,
  },
  {
    value: "America/Sitka",
    label: "America/Sitka(UTC-09:00)",
    utc: "UTC-09:00",
    offset: -8,
  },
  {
    value: "America/Yakutat",
    label: "America/Yakutat(UTC-09:00)",
    utc: "UTC-09:00",
    offset: -8,
  },
  {
    value: "America/Santa_Isabel",
    label: "America/Santa_Isabel(UTC-08:00)",
    utc: "UTC-08:00",
    offset: -7,
  },
  {
    value: "America/Los_Angeles",
    label: "America/Los_Angeles(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Tijuana",
    label: "America/Tijuana(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Vancouver",
    label: "America/Vancouver(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Tijuana",
    label: "America/Tijuana(UTC-08:00)",
    utc: "UTC-08:00",
    offset: -8,
  },
  {
    value: "America/Vancouver",
    label: "America/Vancouver(UTC-08:00)",
    utc: "UTC-08:00",
    offset: -8,
  },
  {
    value: "America/Creston",
    label: "America/Creston(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Dawson",
    label: "America/Dawson(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Dawson_Creek",
    label: "America/Dawson_Creek(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Hermosillo",
    label: "America/Hermosillo(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Phoenix",
    label: "America/Phoenix(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Whitehorse",
    label: "America/Whitehorse(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "Etc/GMT+7",
    label: "Etc/GMT+7(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -7,
  },
  {
    value: "America/Chihuahua",
    label: "America/Chihuahua(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Mazatlan",
    label: "America/Mazatlan(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Boise",
    label: "America/Boise(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Cambridge_Bay",
    label: "America/Cambridge_Bay(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Denver",
    label: "America/Denver(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Edmonton",
    label: "America/Edmonton(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Inuvik",
    label: "America/Inuvik(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Ojinaga",
    label: "America/Ojinaga(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Yellowknife",
    label: "America/Yellowknife(UTC-07:00)",
    utc: "UTC-07:00",
    offset: -6,
  },
  {
    value: "America/Belize",
    label: "America/Belize(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Costa_Rica",
    label: "America/Costa_Rica(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/El_Salvador",
    label: "America/El_Salvador(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Guatemala",
    label: "America/Guatemala(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Managua",
    label: "America/Managua(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Tegucigalpa",
    label: "America/Tegucigalpa(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "Etc/GMT+6",
    label: "Etc/GMT+6(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "Pacific/Galapagos",
    label: "Pacific/Galapagos(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Chicago",
    label: "America/Chicago(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Indiana/Knox",
    label: "America/Indiana/Knox(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Indiana/Tell_City",
    label: "America/Indiana/Tell_City(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Matamoros",
    label: "America/Matamoros(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Menominee",
    label: "America/Menominee(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/North_Dakota/Beulah",
    label: "America/North_Dakota/Beulah(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/North_Dakota/Center",
    label: "America/North_Dakota/Center(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "America/North_Dakota/New_Salem(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Rainy_River",
    label: "America/Rainy_River(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Rankin_Inlet",
    label: "America/Rankin_Inlet(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Resolute",
    label: "America/Resolute(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Winnipeg",
    label: "America/Winnipeg(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Bahia_Banderas",
    label: "America/Bahia_Banderas(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Cancun",
    label: "America/Cancun(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Merida",
    label: "America/Merida(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Mexico_City",
    label: "America/Mexico_City(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Monterrey",
    label: "America/Monterrey(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -5,
  },
  {
    value: "America/Regina",
    label: "America/Regina(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Swift_Current",
    label: "America/Swift_Current(UTC-06:00)",
    utc: "UTC-06:00",
    offset: -6,
  },
  {
    value: "America/Bogota",
    label: "America/Bogota(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Cayman",
    label: "America/Cayman(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Coral_Harbour",
    label: "America/Coral_Harbour(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Eirunepe",
    label: "America/Eirunepe(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Guayaquil",
    label: "America/Guayaquil(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Jamaica",
    label: "America/Jamaica(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Lima",
    label: "America/Lima(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Panama",
    label: "America/Panama(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Rio_Branco",
    label: "America/Rio_Branco(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "Etc/GMT+5",
    label: "Etc/GMT+5(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Detroit",
    label: "America/Detroit(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Havana",
    label: "America/Havana(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Indiana/Petersburg",
    label: "America/Indiana/Petersburg(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Indiana/Vincennes",
    label: "America/Indiana/Vincennes(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Indiana/Winamac",
    label: "America/Indiana/Winamac(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Iqaluit",
    label: "America/Iqaluit(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Kentucky/Monticello",
    label: "America/Kentucky/Monticello(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Louisville",
    label: "America/Louisville(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Montreal",
    label: "America/Montreal(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Nassau",
    label: "America/Nassau(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/New_York",
    label: "America/New_York(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Nipigon",
    label: "America/Nipigon(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Pangnirtung",
    label: "America/Pangnirtung(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Port-au-Prince",
    label: "America/Port-au-Prince(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Thunder_Bay",
    label: "America/Thunder_Bay(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Toronto",
    label: "America/Toronto(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Detroit",
    label: "America/Detroit(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Havana",
    label: "America/Havana(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Indiana/Petersburg",
    label: "America/Indiana/Petersburg(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Indiana/Vincennes",
    label: "America/Indiana/Vincennes(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Indiana/Winamac",
    label: "America/Indiana/Winamac(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Iqaluit",
    label: "America/Iqaluit(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Kentucky/Monticello",
    label: "America/Kentucky/Monticello(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Louisville",
    label: "America/Louisville(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Montreal",
    label: "America/Montreal(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Nassau",
    label: "America/Nassau(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/New_York",
    label: "America/New_York(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Nipigon",
    label: "America/Nipigon(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Pangnirtung",
    label: "America/Pangnirtung(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Port-au-Prince",
    label: "America/Port-au-Prince(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Thunder_Bay",
    label: "America/Thunder_Bay(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Toronto",
    label: "America/Toronto(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Indiana/Marengo",
    label: "America/Indiana/Marengo(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Indiana/Vevay",
    label: "America/Indiana/Vevay(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Indianapolis",
    label: "America/Indianapolis(UTC-05:00)",
    utc: "UTC-05:00",
    offset: -5,
  },
  {
    value: "America/Caracas",
    label: "America/Caracas(UTC-04:30)",
    utc: "UTC-04:30",
    offset: -4.5,
  },
  {
    value: "America/Asuncion",
    label: "America/Asuncion(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Glace_Bay",
    label: "America/Glace_Bay(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -3,
  },
  {
    value: "America/Goose_Bay",
    label: "America/Goose_Bay(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -3,
  },
  {
    value: "America/Halifax",
    label: "America/Halifax(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -3,
  },
  {
    value: "America/Moncton",
    label: "America/Moncton(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -3,
  },
  {
    value: "America/Thule",
    label: "America/Thule(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -3,
  },
  {
    value: "Atlantic/Bermuda",
    label: "Atlantic/Bermuda(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -3,
  },
  {
    value: "America/Campo_Grande",
    label: "America/Campo_Grande(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Cuiaba",
    label: "America/Cuiaba(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Anguilla",
    label: "America/Anguilla(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Antigua",
    label: "America/Antigua(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Aruba",
    label: "America/Aruba(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Barbados",
    label: "America/Barbados(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Blanc-Sablon",
    label: "America/Blanc-Sablon(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Boa_Vista",
    label: "America/Boa_Vista(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Curacao",
    label: "America/Curacao(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Dominica",
    label: "America/Dominica(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Grand_Turk",
    label: "America/Grand_Turk(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Grenada",
    label: "America/Grenada(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Guadeloupe",
    label: "America/Guadeloupe(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Guyana",
    label: "America/Guyana(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Kralendijk",
    label: "America/Kralendijk(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/La_Paz",
    label: "America/La_Paz(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Lower_Princes",
    label: "America/Lower_Princes(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Manaus",
    label: "America/Manaus(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Marigot",
    label: "America/Marigot(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Martinique",
    label: "America/Martinique(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Montserrat",
    label: "America/Montserrat(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Port_of_Spain",
    label: "America/Port_of_Spain(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Porto_Velho",
    label: "America/Porto_Velho(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Puerto_Rico",
    label: "America/Puerto_Rico(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Santo_Domingo",
    label: "America/Santo_Domingo(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/St_Barthelemy",
    label: "America/St_Barthelemy(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/St_Kitts",
    label: "America/St_Kitts(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/St_Lucia",
    label: "America/St_Lucia(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/St_Thomas",
    label: "America/St_Thomas(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/St_Vincent",
    label: "America/St_Vincent(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Tortola",
    label: "America/Tortola(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "Etc/GMT+4",
    label: "Etc/GMT+4(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/Santiago",
    label: "America/Santiago(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "Antarctica/Palmer",
    label: "Antarctica/Palmer(UTC-04:00)",
    utc: "UTC-04:00",
    offset: -4,
  },
  {
    value: "America/St_Johns",
    label: "America/St_Johns(UTC-03:30)",
    utc: "UTC-03:30",
    offset: -2.5,
  },
  {
    value: "America/Sao_Paulo",
    label: "America/Sao_Paulo(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/La_Rioja",
    label: "America/Argentina/La_Rioja(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    label: "America/Argentina/Rio_Gallegos(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/Salta",
    label: "America/Argentina/Salta(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/San_Juan",
    label: "America/Argentina/San_Juan(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/San_Luis",
    label: "America/Argentina/San_Luis(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/Tucuman",
    label: "America/Argentina/Tucuman(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Argentina/Ushuaia",
    label: "America/Argentina/Ushuaia(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Buenos_Aires",
    label: "America/Buenos_Aires(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Catamarca",
    label: "America/Catamarca(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Cordoba",
    label: "America/Cordoba(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Jujuy",
    label: "America/Jujuy(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Mendoza",
    label: "America/Mendoza(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Araguaina",
    label: "America/Araguaina(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Belem",
    label: "America/Belem(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Cayenne",
    label: "America/Cayenne(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Fortaleza",
    label: "America/Fortaleza(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Maceio",
    label: "America/Maceio(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Paramaribo",
    label: "America/Paramaribo(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Recife",
    label: "America/Recife(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Santarem",
    label: "America/Santarem(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "Antarctica/Rothera",
    label: "Antarctica/Rothera(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "Atlantic/Stanley",
    label: "Atlantic/Stanley(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "Etc/GMT+3",
    label: "Etc/GMT+3(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Godthab",
    label: "America/Godthab(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Montevideo",
    label: "America/Montevideo(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Bahia",
    label: "America/Bahia(UTC-03:00)",
    utc: "UTC-03:00",
    offset: -3,
  },
  {
    value: "America/Noronha",
    label: "America/Noronha(UTC-02:00)",
    utc: "UTC-02:00",
    offset: -2,
  },
  {
    value: "Atlantic/South_Georgia",
    label: "Atlantic/South_Georgia(UTC-02:00)",
    utc: "UTC-02:00",
    offset: -2,
  },
  {
    value: "Etc/GMT+2",
    label: "Etc/GMT+2(UTC-02:00)",
    utc: "UTC-02:00",
    offset: -2,
  },
  {
    value: "America/Scoresbysund",
    label: "America/Scoresbysund(UTC-01:00)",
    utc: "UTC-01:00",
    offset: 0,
  },
  {
    value: "Atlantic/Azores",
    label: "Atlantic/Azores(UTC-01:00)",
    utc: "UTC-01:00",
    offset: 0,
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "Atlantic/Cape_Verde(UTC-01:00)",
    utc: "UTC-01:00",
    offset: -1,
  },
  {
    value: "Etc/GMT+1",
    label: "Etc/GMT+1(UTC-01:00)",
    utc: "UTC-01:00",
    offset: -1,
  },
  {
    value: "Africa/Casablanca",
    label: "Africa/Casablanca(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "Africa/El_Aaiun",
    label: "Africa/El_Aaiun(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "America/Danmarkshavn",
    label: "America/Danmarkshavn(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Etc/GMT",
    label: "Etc/GMT(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Europe/Isle_of_Man",
    label: "Europe/Isle_of_Man(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Europe/Guernsey",
    label: "Europe/Guernsey(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Europe/Jersey",
    label: "Europe/Jersey(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Europe/London",
    label: "Europe/London(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Europe/Isle_of_Man",
    label: "Europe/Isle_of_Man(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Europe/Guernsey",
    label: "Europe/Guernsey(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Europe/Jersey",
    label: "Europe/Jersey(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Europe/London",
    label: "Europe/London(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Atlantic/Canary",
    label: "Atlantic/Canary(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "Atlantic/Faeroe",
    label: "Atlantic/Faeroe(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "Atlantic/Madeira",
    label: "Atlantic/Madeira(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "Europe/Dublin",
    label: "Europe/Dublin(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "Europe/Lisbon",
    label: "Europe/Lisbon(UTC)",
    utc: "UTC",
    offset: 1,
  },
  {
    value: "Africa/Abidjan",
    label: "Africa/Abidjan(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Accra",
    label: "Africa/Accra(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Bamako",
    label: "Africa/Bamako(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Banjul",
    label: "Africa/Banjul(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Bissau",
    label: "Africa/Bissau(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Conakry",
    label: "Africa/Conakry(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Dakar",
    label: "Africa/Dakar(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Freetown",
    label: "Africa/Freetown(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Lome",
    label: "Africa/Lome(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Monrovia",
    label: "Africa/Monrovia(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Nouakchott",
    label: "Africa/Nouakchott(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Ouagadougou",
    label: "Africa/Ouagadougou(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Africa/Sao_Tome",
    label: "Africa/Sao_Tome(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Atlantic/Reykjavik",
    label: "Atlantic/Reykjavik(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Atlantic/St_Helena",
    label: "Atlantic/St_Helena(UTC)",
    utc: "UTC",
    offset: 0,
  },
  {
    value: "Arctic/Longyearbyen",
    label: "Arctic/Longyearbyen(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Amsterdam",
    label: "Europe/Amsterdam(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Andorra",
    label: "Europe/Andorra(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Berlin",
    label: "Europe/Berlin(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Busingen",
    label: "Europe/Busingen(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Gibraltar",
    label: "Europe/Gibraltar(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Luxembourg",
    label: "Europe/Luxembourg(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Malta",
    label: "Europe/Malta(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Monaco",
    label: "Europe/Monaco(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Oslo",
    label: "Europe/Oslo(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Rome",
    label: "Europe/Rome(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/San_Marino",
    label: "Europe/San_Marino(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Stockholm",
    label: "Europe/Stockholm(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Vaduz",
    label: "Europe/Vaduz(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Vatican",
    label: "Europe/Vatican(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Vienna",
    label: "Europe/Vienna(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Zurich",
    label: "Europe/Zurich(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Belgrade",
    label: "Europe/Belgrade(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Bratislava",
    label: "Europe/Bratislava(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Budapest",
    label: "Europe/Budapest(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Ljubljana",
    label: "Europe/Ljubljana(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Podgorica",
    label: "Europe/Podgorica(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Prague",
    label: "Europe/Prague(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Tirane",
    label: "Europe/Tirane(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Africa/Ceuta",
    label: "Africa/Ceuta(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Brussels",
    label: "Europe/Brussels(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Copenhagen",
    label: "Europe/Copenhagen(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Madrid",
    label: "Europe/Madrid(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Paris",
    label: "Europe/Paris(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Sarajevo",
    label: "Europe/Sarajevo(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Skopje",
    label: "Europe/Skopje(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Warsaw",
    label: "Europe/Warsaw(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Europe/Zagreb",
    label: "Europe/Zagreb(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 2,
  },
  {
    value: "Africa/Algiers",
    label: "Africa/Algiers(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Bangui",
    label: "Africa/Bangui(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Brazzaville",
    label: "Africa/Brazzaville(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Douala",
    label: "Africa/Douala(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Kinshasa",
    label: "Africa/Kinshasa(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Lagos",
    label: "Africa/Lagos(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Libreville",
    label: "Africa/Libreville(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Luanda",
    label: "Africa/Luanda(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Malabo",
    label: "Africa/Malabo(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Ndjamena",
    label: "Africa/Ndjamena(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Niamey",
    label: "Africa/Niamey(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Porto-Novo",
    label: "Africa/Porto-Novo(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Tunis",
    label: "Africa/Tunis(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Etc/GMT-1",
    label: "Etc/GMT-1(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Africa/Windhoek",
    label: "Africa/Windhoek(UTC+01:00)",
    utc: "UTC+01:00",
    offset: 1,
  },
  {
    value: "Asia/Nicosia",
    label: "Asia/Nicosia(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Athens",
    label: "Europe/Athens(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Bucharest",
    label: "Europe/Bucharest(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Chisinau",
    label: "Europe/Chisinau(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Asia/Beirut",
    label: "Asia/Beirut(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Africa/Cairo",
    label: "Africa/Cairo(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Asia/Damascus",
    label: "Asia/Damascus(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Asia/Nicosia",
    label: "Asia/Nicosia(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Athens",
    label: "Europe/Athens(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Bucharest",
    label: "Europe/Bucharest(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Chisinau",
    label: "Europe/Chisinau(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Helsinki",
    label: "Europe/Helsinki(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Kiev",
    label: "Europe/Kiev(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Mariehamn",
    label: "Europe/Mariehamn(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Nicosia",
    label: "Europe/Nicosia(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Riga",
    label: "Europe/Riga(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Sofia",
    label: "Europe/Sofia(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Tallinn",
    label: "Europe/Tallinn(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Uzhgorod",
    label: "Europe/Uzhgorod(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Vilnius",
    label: "Europe/Vilnius(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Zaporozhye",
    label: "Europe/Zaporozhye(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Africa/Blantyre",
    label: "Africa/Blantyre(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Bujumbura",
    label: "Africa/Bujumbura(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Gaborone",
    label: "Africa/Gaborone(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Harare",
    label: "Africa/Harare(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Johannesburg",
    label: "Africa/Johannesburg(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Kigali",
    label: "Africa/Kigali(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Lubumbashi",
    label: "Africa/Lubumbashi(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Lusaka",
    label: "Africa/Lusaka(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Maputo",
    label: "Africa/Maputo(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Maseru",
    label: "Africa/Maseru(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Africa/Mbabane",
    label: "Africa/Mbabane(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Etc/GMT-2",
    label: "Etc/GMT-2(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Europe/Helsinki",
    label: "Europe/Helsinki(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Kiev",
    label: "Europe/Kiev(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Mariehamn",
    label: "Europe/Mariehamn(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Riga",
    label: "Europe/Riga(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Sofia",
    label: "Europe/Sofia(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Tallinn",
    label: "Europe/Tallinn(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Uzhgorod",
    label: "Europe/Uzhgorod(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Vilnius",
    label: "Europe/Vilnius(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Zaporozhye",
    label: "Europe/Zaporozhye(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Europe/Istanbul",
    label: "Europe/Istanbul(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Asia/Jerusalem",
    label: "Asia/Jerusalem(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Africa/Tripoli",
    label: "Africa/Tripoli(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 2,
  },
  {
    value: "Asia/Amman",
    label: "Asia/Amman(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Asia/Baghdad",
    label: "Asia/Baghdad(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Kaliningrad",
    label: "Europe/Kaliningrad(UTC+02:00)",
    utc: "UTC+02:00",
    offset: 3,
  },
  {
    value: "Asia/Aden",
    label: "Asia/Aden(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Asia/Bahrain",
    label: "Asia/Bahrain(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Asia/Kuwait",
    label: "Asia/Kuwait(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Asia/Qatar",
    label: "Asia/Qatar(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Asia/Riyadh",
    label: "Asia/Riyadh(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Addis_Ababa",
    label: "Africa/Addis_Ababa(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Asmera",
    label: "Africa/Asmera(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "Africa/Dar_es_Salaam(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Djibouti",
    label: "Africa/Djibouti(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Juba",
    label: "Africa/Juba(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Kampala",
    label: "Africa/Kampala(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Khartoum",
    label: "Africa/Khartoum(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Mogadishu",
    label: "Africa/Mogadishu(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Africa/Nairobi",
    label: "Africa/Nairobi(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Antarctica/Syowa",
    label: "Antarctica/Syowa(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Etc/GMT-3",
    label: "Etc/GMT-3(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Indian/Antananarivo",
    label: "Indian/Antananarivo(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Indian/Comoro",
    label: "Indian/Comoro(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Indian/Mayotte",
    label: "Indian/Mayotte(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Kirov",
    label: "Europe/Kirov(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Moscow",
    label: "Europe/Moscow(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Simferopol",
    label: "Europe/Simferopol(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Volgograd",
    label: "Europe/Volgograd(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Minsk",
    label: "Europe/Minsk(UTC+03:00)",
    utc: "UTC+03:00",
    offset: 3,
  },
  {
    value: "Europe/Astrakhan",
    label: "Europe/Astrakhan(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Europe/Samara",
    label: "Europe/Samara(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Europe/Ulyanovsk",
    label: "Europe/Ulyanovsk(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Asia/Tehran",
    label: "Asia/Tehran(UTC+03:30)",
    utc: "UTC+03:30",
    offset: 4.5,
  },
  {
    value: "Asia/Dubai",
    label: "Asia/Dubai(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Asia/Muscat",
    label: "Asia/Muscat(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Etc/GMT-4",
    label: "Etc/GMT-4(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Asia/Baku",
    label: "Asia/Baku(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 5,
  },
  {
    value: "Indian/Mahe",
    label: "Indian/Mahe(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Indian/Mauritius",
    label: "Indian/Mauritius(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Indian/Reunion",
    label: "Indian/Reunion(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Asia/Tbilisi",
    label: "Asia/Tbilisi(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Asia/Yerevan",
    label: "Asia/Yerevan(UTC+04:00)",
    utc: "UTC+04:00",
    offset: 4,
  },
  {
    value: "Asia/Kabul",
    label: "Asia/Kabul(UTC+04:30)",
    utc: "UTC+04:30",
    offset: 4.5,
  },
  {
    value: "Antarctica/Mawson",
    label: "Antarctica/Mawson(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Aqtau",
    label: "Asia/Aqtau(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Aqtobe",
    label: "Asia/Aqtobe(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Ashgabat",
    label: "Asia/Ashgabat(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Dushanbe",
    label: "Asia/Dushanbe(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Oral",
    label: "Asia/Oral(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Samarkand",
    label: "Asia/Samarkand(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Tashkent",
    label: "Asia/Tashkent(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Etc/GMT-5",
    label: "Etc/GMT-5(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Indian/Kerguelen",
    label: "Indian/Kerguelen(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Indian/Maldives",
    label: "Indian/Maldives(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Yekaterinburg",
    label: "Asia/Yekaterinburg(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Karachi",
    label: "Asia/Karachi(UTC+05:00)",
    utc: "UTC+05:00",
    offset: 5,
  },
  {
    value: "Asia/Kolkata",
    label: "Asia/Kolkata(UTC+05:30)",
    utc: "UTC+05:30",
    offset: 5.5,
  },
  {
    value: "Asia/Calcutta",
    label: "Asia/Calcutta(UTC+05:30)",
    utc: "UTC+05:30",
    offset: 5.5,
  },
  {
    value: "Asia/Colombo",
    label: "Asia/Colombo(UTC+05:30)",
    utc: "UTC+05:30",
    offset: 5.5,
  },
  {
    value: "Asia/Kathmandu",
    label: "Asia/Kathmandu(UTC+05:45)",
    utc: "UTC+05:45",
    offset: 5.75,
  },
  {
    value: "Antarctica/Vostok",
    label: "Antarctica/Vostok(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Almaty",
    label: "Asia/Almaty(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Bishkek",
    label: "Asia/Bishkek(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Qyzylorda",
    label: "Asia/Qyzylorda(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Urumqi",
    label: "Asia/Urumqi(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Etc/GMT-6",
    label: "Etc/GMT-6(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Indian/Chagos",
    label: "Indian/Chagos(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Dhaka",
    label: "Asia/Dhaka(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Thimphu",
    label: "Asia/Thimphu(UTC+06:00)",
    utc: "UTC+06:00",
    offset: 6,
  },
  {
    value: "Asia/Rangoon",
    label: "Asia/Rangoon(UTC+06:30)",
    utc: "UTC+06:30",
    offset: 6.5,
  },
  {
    value: "Indian/Cocos",
    label: "Indian/Cocos(UTC+06:30)",
    utc: "UTC+06:30",
    offset: 6.5,
  },
  {
    value: "Antarctica/Davis",
    label: "Antarctica/Davis(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Bangkok",
    label: "Asia/Bangkok(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Hovd",
    label: "Asia/Hovd(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Jakarta",
    label: "Asia/Jakarta(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Phnom_Penh",
    label: "Asia/Phnom_Penh(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Pontianak",
    label: "Asia/Pontianak(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Saigon",
    label: "Asia/Saigon(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Vientiane",
    label: "Asia/Vientiane(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Etc/GMT-7",
    label: "Etc/GMT-7(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Indian/Christmas",
    label: "Indian/Christmas(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Novokuznetsk",
    label: "Asia/Novokuznetsk(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Novosibirsk",
    label: "Asia/Novosibirsk(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Omsk",
    label: "Asia/Omsk(UTC+07:00)",
    utc: "UTC+07:00",
    offset: 7,
  },
  {
    value: "Asia/Hong_Kong",
    label: "Asia/Hong_Kong(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Macau",
    label: "Asia/Macau(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Shanghai",
    label: "Asia/Shanghai(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "Asia/Krasnoyarsk(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Brunei",
    label: "Asia/Brunei(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "Asia/Kuala_Lumpur(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Kuching",
    label: "Asia/Kuching(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Makassar",
    label: "Asia/Makassar(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Manila",
    label: "Asia/Manila(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Singapore",
    label: "Asia/Singapore(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Etc/GMT-8",
    label: "Etc/GMT-8(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Antarctica/Casey",
    label: "Antarctica/Casey(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Australia/Perth",
    label: "Australia/Perth(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Taipei",
    label: "Asia/Taipei(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Choibalsan",
    label: "Asia/Choibalsan(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "Asia/Ulaanbaatar(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Irkutsk",
    label: "Asia/Irkutsk(UTC+08:00)",
    utc: "UTC+08:00",
    offset: 8,
  },
  {
    value: "Asia/Dili",
    label: "Asia/Dili(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Asia/Jayapura",
    label: "Asia/Jayapura(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Asia/Tokyo",
    label: "Asia/Tokyo(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Etc/GMT-9",
    label: "Etc/GMT-9(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Pacific/Palau",
    label: "Pacific/Palau(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Asia/Pyongyang",
    label: "Asia/Pyongyang(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Asia/Seoul",
    label: "Asia/Seoul(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Australia/Adelaide",
    label: "Australia/Adelaide(UTC+09:30)",
    utc: "UTC+09:30",
    offset: 9.5,
  },
  {
    value: "Australia/Broken_Hill",
    label: "Australia/Broken_Hill(UTC+09:30)",
    utc: "UTC+09:30",
    offset: 9.5,
  },
  {
    value: "Australia/Darwin",
    label: "Australia/Darwin(UTC+09:30)",
    utc: "UTC+09:30",
    offset: 9.5,
  },
  {
    value: "Australia/Brisbane",
    label: "Australia/Brisbane(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Australia/Lindeman",
    label: "Australia/Lindeman(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Australia/Melbourne",
    label: "Australia/Melbourne(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Australia/Sydney",
    label: "Australia/Sydney(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Antarctica/DumontDUrville",
    label: "Antarctica/DumontDUrville(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Etc/GMT-10",
    label: "Etc/GMT-10(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Pacific/Guam",
    label: "Pacific/Guam(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Pacific/Port_Moresby",
    label: "Pacific/Port_Moresby(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Pacific/Saipan",
    label: "Pacific/Saipan(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Pacific/Truk",
    label: "Pacific/Truk(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Australia/Currie",
    label: "Australia/Currie(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Australia/Hobart",
    label: "Australia/Hobart(UTC+10:00)",
    utc: "UTC+10:00",
    offset: 10,
  },
  {
    value: "Asia/Chita",
    label: "Asia/Chita(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Asia/Khandyga",
    label: "Asia/Khandyga(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Asia/Yakutsk",
    label: "Asia/Yakutsk(UTC+09:00)",
    utc: "UTC+09:00",
    offset: 9,
  },
  {
    value: "Antarctica/Macquarie",
    label: "Antarctica/Macquarie(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Etc/GMT-11",
    label: "Etc/GMT-11(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Pacific/Efate",
    label: "Pacific/Efate(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Pacific/Guadalcanal",
    label: "Pacific/Guadalcanal(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Pacific/Kosrae",
    label: "Pacific/Kosrae(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Pacific/Noumea",
    label: "Pacific/Noumea(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Pacific/Ponape",
    label: "Pacific/Ponape(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Asia/Sakhalin",
    label: "Asia/Sakhalin(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Asia/Ust-Nera",
    label: "Asia/Ust-Nera(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Asia/Vladivostok",
    label: "Asia/Vladivostok(UTC+11:00)",
    utc: "UTC+11:00",
    offset: 11,
  },
  {
    value: "Antarctica/McMurdo",
    label: "Antarctica/McMurdo(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Auckland",
    label: "Pacific/Auckland(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Etc/GMT-12",
    label: "Etc/GMT-12(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Funafuti",
    label: "Pacific/Funafuti(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Kwajalein",
    label: "Pacific/Kwajalein(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Majuro",
    label: "Pacific/Majuro(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Nauru",
    label: "Pacific/Nauru(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Tarawa",
    label: "Pacific/Tarawa(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Wake",
    label: "Pacific/Wake(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Wallis",
    label: "Pacific/Wallis(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Pacific/Fiji",
    label: "Pacific/Fiji(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Asia/Anadyr",
    label: "Asia/Anadyr(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Asia/Kamchatka",
    label: "Asia/Kamchatka(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Asia/Magadan",
    label: "Asia/Magadan(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Asia/Srednekolymsk",
    label: "Asia/Srednekolymsk(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 12,
  },
  {
    value: "Asia/Kamchatka",
    label: "Asia/Kamchatka(UTC+12:00)",
    utc: "UTC+12:00",
    offset: 13,
  },
  {
    value: "Etc/GMT-13",
    label: "Etc/GMT-13(UTC+13:00)",
    utc: "UTC+13:00",
    offset: 13,
  },
  {
    value: "Pacific/Enderbury",
    label: "Pacific/Enderbury(UTC+13:00)",
    utc: "UTC+13:00",
    offset: 13,
  },
  {
    value: "Pacific/Fakaofo",
    label: "Pacific/Fakaofo(UTC+13:00)",
    utc: "UTC+13:00",
    offset: 13,
  },
  {
    value: "Pacific/Tongatapu",
    label: "Pacific/Tongatapu(UTC+13:00)",
    utc: "UTC+13:00",
    offset: 13,
  },
  {
    value: "Pacific/Apia",
    label: "Pacific/Apia(UTC+13:00)",
    utc: "UTC+13:00",
    offset: 13,
  },
];

export const weekDays = [
  {
    label: "Sunday",
    value: "0",
  },
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
];

export const StatusOptions = [
  {
    label: "ACTIVE",
    value: "START",
  },
  {
    label: "STOPPED",
    value: "STOPPED",
  },
  {
    label: "PAUSED",
    value: "PAUSED",
  },
];
