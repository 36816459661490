import { Box } from "@chakra-ui/react";
import CampaignReach from "./Analytics/CampaignReach";
import DealValue from "./Analytics/DealValue";
import EmailReach from "./Analytics/EmailReach";
import LeadQuality from "./Analytics/LeadQuality";
import { useEffect, useRef, useState } from "react";
import useAnalytics from "./hooks/useAnalytics";
import { format, subDays, addDays } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const generateDateRange = (start: string, end: string) => {
  const dates = [];
  let currentDate = new Date(start);
  const endDate = new Date(end);

  while (currentDate <= endDate) {
    dates.push(format(currentDate, "yyyy-MM-dd"));
    currentDate = addDays(currentDate, 1);
  }

  return dates;
};

const Analytics = ({ campaignId }: { campaignId: number }) => {
  // State for date range
  const [dateRange, setDateRange] = useState({
    startDate: format(subDays(new Date(), 7), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  });

  // State for controlling visibility of date picker
  const [calendarVisible, setCalendarVisible] = useState(false);

  // Ref to detect clicks outside the calendar or date range selector
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const dateRangeRef = useRef<HTMLDivElement | null>(null);

  // Fetch analytics data with updated dates
  const { data: campaignAnalytics, refetch } = useAnalytics(
    campaignId,
    dateRange.startDate,
    dateRange.endDate,
  ) as any;

  // Generate dates between start_date and end_date
  const dates = generateDateRange(dateRange.startDate, dateRange.endDate);

  const handleDateChange = (ranges: any) => {
    const { selection } = ranges;
    setDateRange({
      startDate: format(selection.startDate, "yyyy-MM-dd"),
      endDate: format(selection.endDate, "yyyy-MM-dd"),
    });
  };

  useEffect(() => {
    refetch(); // Re-fetch data whenever date range changes
  }, [dateRange, refetch]);

  // Close calendar if clicking outside the calendar and date range selector
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node) &&
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target as Node)
      ) {
        setCalendarVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle calendar visibility
  const toggleCalendar = () => {
    setCalendarVisible((prev) => !prev);
  };
  return (
    <>
      <div className="flex items-center justify-start">
        {/* Date Range Selector */}
        <Box ref={dateRangeRef}>
          <div className="flex items-center space-x-4 bg-white">
            <p className="text-[16px] font-medium text-[#373F51]">
              Select Date Range:
            </p>
            {/* Start Date */}
            <Box
              onClick={toggleCalendar}
              cursor="pointer"
              className="border p-2 rounded-md text-[13px]"
            >
              {dateRange.startDate}
            </Box>
            {/* End Date */}
            <Box
              onClick={toggleCalendar}
              cursor="pointer"
              className="border p-2 rounded-md text-[13px]"
            >
              {dateRange.endDate}
            </Box>
          </div>
          {/* DateRange calendar, visible when toggled */}
          {calendarVisible && (
            <div ref={calendarRef} className="absolute z-50">
              <DateRange
                ranges={[
                  {
                    startDate: new Date(dateRange.startDate),
                    endDate: new Date(dateRange.endDate),
                    key: "selection",
                  },
                ]}
                onChange={handleDateChange}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
                showDateDisplay={false}
              />
            </div>
          )}
        </Box>
      </div>
      <div className="flex flex-col gap-4 mt-2">
        <div className="flex items-center gap-3">
          <EmailReach campaignAnalytics={campaignAnalytics?.data || {}} />
          <DealValue />
        </div>
        <div className="flex items-center gap-3">
          <CampaignReach
            campaignAnalytics={campaignAnalytics?.data || {}}
            dates={dates || []}
          />
          <LeadQuality campaignAnalytics={campaignAnalytics?.data || {}} />
        </div>
      </div>
    </>
  );
};

export default Analytics;
