import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Button,
  Divider,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from "@chakra-ui/react";

import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@/lib/queryClient";
import campaignService from "@/services/campaign.service";
import SequenceSidebar from "./SequenceSidebar";
// import persanaLogo from "@/assets/images/logo.png";
import Flowchart from "./Flowchart";
import { useCampaignStore } from "@/stores/campaigns.store";

const Sequences = ({ campaignId }: { campaignId: number }) => {
  const [haveFullAccess, setHaveFullAccess] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const campaignDetails = useCampaignStore((state) => state.campaignDetails);

  const variableOptions = [
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Email", value: "email" },
    { label: "Company Name", value: "company_name" },
    { label: "Website", value: "website" },
    { label: "Phone Number", value: "phone_number" },
    { label: "Linkedin Profile", value: "linkedin_profile" },
    { label: "Location", value: "location" },
  ];

  const [activeSequenceIndex, setActiveSequenceIndex] = useState(0);
  const [sequences, setSequences] = useState([
    {
      id: null,
      seq_number: 1,
      seq_delay_details: { delay_in_days: 10 },
      variant_distribution_type: "MANUAL_EQUAL",
      lead_distribution_percentage: 40,
      winning_metric_property: "OPEN_RATE",
      seq_variants: [
        {
          subject: "",
          email_body: "",
          variant_label: "A",
          variant_distribution_percentage: 100,
        },
      ],
    },
  ]);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => campaignService.saveSequence(campaignId, sequences),
  });

  const { mutateAsync: getSequenceMutateAsync } = useMutation({
    mutationFn: () => campaignService.getSequenceDetails(campaignId),
  });

  // const { mutateAsync: generateTemplateMutateAsync, isPending: isLoading } =
  //   useMutation({
  //     mutationFn: () => campaignService.generateEmailWithAI(campaignId || 0),
  //   });

  const getSequenceDetails = async () => {
    await getSequenceMutateAsync(undefined, {
      onSuccess(response: any) {
        if (response?.success) {
          const fetchedSequences = response?.data?.map(
            (seq: any, index: number) => ({
              id: null,
              seq_number: seq?.seq_number || index + 1,
              seq_delay_details: Number(seq?.seq_delay_details) || {
                delay_in_days: 10,
              },
              variant_distribution_type:
                seq?.variant_distribution_type || "MANUAL_EQUAL",
              lead_distribution_percentage:
                seq?.lead_distribution_percentage || 40,
              winning_metric_property:
                seq?.winning_metric_property || "OPEN_RATE",
              seq_variants:
                seq?.sequence_variants?.map((variant: any) => ({
                  subject: variant?.subject || "",
                  email_body: variant?.email_body || "",
                  variant_label: variant?.variant_label || "A",
                  variant_distribution_percentage:
                    variant?.variant_distribution_percentage || 100,
                })) || [],
            }),
          );
          fetchedSequences?.length && setSequences(fetchedSequences);
        } else {
          toast({
            title: "Error",
            description: "Failed to fetch sequence details",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      onError(error) {
        toast({
          title: "Error",
          description: error?.message || "Failed to fetch sequence details",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  const handleSave = async () => {
    await mutateAsync(undefined, {
      onSuccess(response) {
        if (response?.success) {
          queryClient.refetchQueries({ queryKey: ["campaignDetails"] });
          toast({
            title: "Success",
            description: "Sequence saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "Error",
            description: response?.error?.message || "Failed to save sequence",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      onError(error) {
        toast({
          title: "Error",
          description: error?.message || "Failed to save sequence",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };
  const handleVariableClick = (variable: string) => {
    handleSequenceUpdate(activeSequenceIndex, {
      seq_variants: [
        {
          ...sequences[activeSequenceIndex].seq_variants[0],
          subject: `${sequences[activeSequenceIndex].seq_variants?.[0]?.subject} {{${variable}}}`,
        },
      ],
    });

    onClose();
  };

  // const handleGenerateWithAI = (template: string) => {
  //   handleSequenceUpdate(activeSequenceIndex, {
  //     seq_variants: [
  //       {
  //         ...sequences[activeSequenceIndex].seq_variants[0],
  //         email_body: `${sequences[activeSequenceIndex].seq_variants[0].email_body}
  //           ${template}`,
  //       },
  //     ],
  //   });
  // };

  // const handleGenerate = async () => {
  //   await generateTemplateMutateAsync(undefined, {
  //     onSuccess(response: any) {
  //       if (response?.success) {
  //         handleGenerateWithAI(response?.data?.data || "");
  //       } else {
  //         toast({
  //           title: "Error",
  //           description:
  //             response?.error?.message || "Failed to generate email!",
  //           status: "error",
  //           duration: 5000,
  //           isClosable: true,
  //           position: "top-right",
  //         });
  //       }
  //     },
  //     onError(error) {
  //       toast({
  //         title: "Error",
  //         description: error?.message || "Failed to generate email!",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //         position: "top-right",
  //       });
  //     },
  //   });
  // };

  const addSequence = () => {
    setActiveSequenceIndex(sequences.length);
    setSequences((prevSequences) => {
      return [
        ...prevSequences,
        {
          id: null,
          seq_number: prevSequences.length + 1,
          seq_delay_details: { delay_in_days: 10 },
          variant_distribution_type: "MANUAL_EQUAL",
          lead_distribution_percentage: 40,
          winning_metric_property: "OPEN_RATE",
          seq_variants: [
            {
              subject: "",
              email_body: "",
              variant_label: String.fromCharCode(65 + prevSequences.length),
              variant_distribution_percentage: 100,
            },
          ],
        },
      ];
    });
  };

  const deleteSequence = (index: number) => {
    const updatedSequences = sequences.filter((_, i) => i !== index);
    setSequences(updatedSequences);

    if (index === activeSequenceIndex) {
      setActiveSequenceIndex(Math.max(0, index - 1));
    }
  };

  const handleSequenceClick = (index: number) => {
    setActiveSequenceIndex(index);
  };

  const handleSequenceUpdate = (index: number, updatedData: any) => {
    const updatedSequences = sequences.map((seq, seqIndex) =>
      seqIndex === index ? { ...seq, ...updatedData } : seq,
    );
    setSequences(updatedSequences);
  };

  const handleFullAccess = (status: boolean) => {
    setHaveFullAccess(status);
  };

  useEffect(() => {
    getSequenceDetails();
  }, []);

  useEffect(() => {
    setHaveFullAccess(campaignDetails.haveFullAccess);
  }, [campaignDetails]);

  if (!haveFullAccess) {
    return (
      <Flowchart
        haveFullAccess={haveFullAccess}
        handleFullAccess={handleFullAccess}
      />
    );
  }

  return (
    <div className="flex">
      <div className="w-[20%] border-r border-[#d0d4dc] h-[82vh]">
        <div className="px-[32px] py-[16px] border-b border-[#d0d4dc]">
          <p className="text-[18px] text-[#0F0D1C] font-medium">Sequences</p>
          <p className="text-[13px] font-regular text-[#4A5264]">
            Choose triggers and setup workflows
          </p>
        </div>
        <SequenceSidebar
          sequences={sequences}
          activeSequenceIndex={activeSequenceIndex}
          onAddSequence={addSequence}
          onSequenceClick={handleSequenceClick}
          onDeleteSequence={deleteSequence}
        />
      </div>
      <div className="w-[80%] p-[24px]">
        <p className="mb-6 ml-2 text-[16px] text-[#0F0D1C]">Message</p>
        <Box className="bg-white shadow-lg px-[24px] py-[16px] border border-[#D0D4DC] rounded-[8px]">
          <div className="flex justify-between h-[40px] border border-[#D0D4DC] rounded-[6px] mb-2">
            <div className="bg-[#F0EFF1] text-[#686b8a] flex items-center rounded-l-[6px]">
              <p className="px-4">Subject:</p>
            </div>
            <input
              className="!h-full !w-[85%] !outline-none !border-none focus:outline-none focus:!border-none px-4"
              value={
                sequences[activeSequenceIndex]?.seq_variants?.[0]?.subject || ""
              }
              onChange={(e) => {
                if (e.target.value) {
                  handleSequenceUpdate(activeSequenceIndex, {
                    seq_variants: [
                      {
                        ...sequences[activeSequenceIndex]?.seq_variants?.[0],
                        subject: e.target.value,
                      },
                    ],
                  });
                }
              }}
              placeholder="Hello {{first_name}}"
            />
            <Popover
              isOpen={isOpen}
              onClose={onClose}
              placement="bottom"
              closeOnBlur
            >
              <PopoverTrigger>
                <div
                  className="hover:!bg-[#f1f3fd] text-[#6e58f1] flex items-center cursor-pointer rounded-r-[6px]"
                  onClick={onOpen}
                >
                  <p className="px-4 font-medium text-sm">{`Variables`}</p>
                </div>
              </PopoverTrigger>
              <PopoverContent className="mr-8 py-2" w={"fit-content"}>
                <PopoverBody className="!m-0 !p-0">
                  {variableOptions?.map((item) => (
                    <p
                      className="px-4 py-2 cursor-pointer hover:bg-[#f1f3fd] w-full"
                      onClick={() => handleVariableClick(item.value)}
                    >
                      {` ${item.label} - {{ ${item.value} }}`}
                    </p>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div>
          <Divider />
          <div className="bg-white pb-2 editor-container">
            <ReactQuill
              modules={{
                toolbar: [
                  [{ color: [] }, { background: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                ],
              }}
              theme="snow"
              value={
                sequences[activeSequenceIndex]?.seq_variants?.[0]?.email_body ||
                ""
              }
              onChange={(value) => {
                if (value && value !== "<p><br></p>") {
                  handleSequenceUpdate(activeSequenceIndex, {
                    seq_variants: [
                      {
                        ...sequences[activeSequenceIndex]?.seq_variants?.[0],
                        email_body: value,
                      },
                    ],
                  });
                }
              }}
            />
          </div>

          <div className="flex items-center justify-end">
            {/* <Button
              size={"sm"}
              variant={"outline"}
              leftIcon={<img src={persanaLogo} width={16} height={16} />}
              onClick={handleGenerate}
              isLoading={isLoading}
            >
              Generate with AI
            </Button> */}
            <Button
              className="w-fit mt-4"
              colorScheme="purple"
              variant={"outline"}
              size={"sm"}
              onClick={handleSave}
              isLoading={isPending}
            >
              Save Changes
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Sequences;
