import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { countries } from "countries-list";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import AccordionButton from "../Common/AccordionButton";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import PersanaInput from "../Common/PersanaInput";
import SearchInputAccordion from "../Common/SearchInputAccordion";

import CompanyIcon from "@/assets/svgs/people-search-company.svg";
import JobTitleIcon from "@/assets/svgs/people-search-job-title.svg";
import LocationIcon from "@/assets/svgs/people-search-location.svg";
import NameIcon from "@/assets/svgs/people-search-name.svg";
import {
  InputtedData,
  useSearchLeadsStore,
} from "@/components/PeopleSearch/store.ts";
import leadService from "@/services/leads.service";

import { SearchCompanyType } from "@/types/leads.types";
import {
  convertSearchPeoplePayload,
  extractNameAndContinent,
  filterValidObjKeys,
  generateId,
  handlePushToArray,
} from "@/utils";
import { apolloCompanySize, jobTitles } from "@/utils/constants";
import { usePeopleSearch } from "./hooks/usePeopleSearch";
import Icons from "../Icons";
import { SaveCurrentFilter } from "./components/SaveFilters";
import { ControlledTabs } from "../Common/TabController";
import SavedSearchesUI from "./components/SavedFilters";
import { calculateAppliedFiltersCount } from "./utils";
import { userStore } from "@/stores/user.store";
import Countries from "../Leads/FindCompanies/Countries";
import CompanyNameSearches from "../Common/Searches/CompanyNameSearch";
import CompanySidebarComponent from "../CompanySearch/Sidebar";
import Educations from "./components/Educations";
import RadioButton from "../Common/RadioButton";
import Seniorities from "./components/Seniorities";
// import campaignService from "@/services/campaign.service";
// import { useCampaignStore } from "@/stores/campaigns.store";
// import { queryClient } from "@/lib/queryClient";
import Industries from "../CompanySearch/Industries";
import { Option } from "../Common/MultiSelectDropdown/types";
import CompanyTypes from "../CompanySearch/CompanyTypes";
import CompanySizes from "../CompanySearch/CompanySizes";
import { useMiscData } from "@/hooks/useMisc";
import SearchPersanaInput from "../Common/PersanaInput/SearchPersanaInput";

const SidebarComponent = ({
  isForAutoPilot = false,
  isForAISDR = false,
}: {
  isForAutoPilot?: boolean;
  isForAISDR?: boolean;
}) => {
  const toast = useToast();
  let timer: NodeJS.Timeout;
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const saveFilterModalDisclosure = useDisclosure();
  const inputtedData = useSearchLeadsStore((state) => state.sideBarInput);
  const isUseMixRank = userStore((state) => state.isUseMixrank);
  const valueLabels = useSearchLeadsStore((state) => state.valueLabels);
  // const selectedCampaign = useCampaignStore((state) => state.selectedCampaign);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const setInputtedData = (data: InputtedData) => {
    updateLeadState({ sideBarInput: data });
  };
  const searchingFor = useSearchLeadsStore((state) => state.searchingFor);
  const totalFilters = useMemo(
    () => calculateAppliedFiltersCount(inputtedData),
    [inputtedData],
  );
  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchCompanyType }) =>
      leadService.searchCompany(payload),
  });

  const { data: companySizesData, isLoading: companySizesLoading } =
    useMiscData<Option[]>("company-sizes");
  const { data: companyTypesData } = useMiscData<Option[]>("company-types");
  const { data: industriesData } = useMiscData<Option[]>("industries");

  const { mutateAsync: searchPeopleMutateAsync } = usePeopleSearch();
  const _peopleSearchPayload = useSearchLeadsStore(
    (state) => state.peopleSearchPayload,
  );
  // const { mutateAsync: campaignDetailsMutateAsync } = useMutation({
  //   mutationFn: (payload: any) =>
  //     campaignService.updateCampaignDetails(payload),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ["campaignDetails"] });
  //   },
  // });
  const applyFilterData = useSearchLeadsStore((state) => state.applyFilterData);
  const id = useSearchLeadsStore((state) => state.instanceId);
  const genId = (field: string) => `${id}-${field}`;

  const handleCompanySearch = async (companyName: string) => {
    // Clear previous timer if it exists
    clearTimeout(timer);

    // Set a new timer to call the API after 0.5s of user inactivity
    timer = setTimeout(async () => {
      await mutateAsync(
        {
          payload: {
            q_organization_name: companyName,
            per_page: 50,
          },
        },
        {
          onSuccess: (response) => {
            // Update the state
            setFetchedCompanies(response?.data.companies || []);
            updateLeadState({
              valueLabels: {
                ...valueLabels,
                ...Object.fromEntries(
                  (response?.data.companies || []).map((company: any) => [
                    company?.organization_id ??
                      company?.id ??
                      company?.owned_by_organization_id,
                    company?.name,
                  ]),
                ),
              },
            });
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    }, 500);
  };

  const handleSearch = useCallback(
    async (inputtedData: InputtedData) => {
      try {
        console.log("myDebug inputtedData", inputtedData);
        const convertedInputtedData = convertSearchPeoplePayload(inputtedData);
        console.log("myDebug convertedInputtedData", convertedInputtedData);

        const validInputtedData = filterValidObjKeys(convertedInputtedData);

        if (Object.keys(validInputtedData || {}).length === 0) {
          toast({
            title: "No data to search",
            description: "Please input some data to search",
            status: "error",
            isClosable: true,
            duration: 2000,
            position: "top-right",
          });
          return;
        }

        console.log("fetchedCompanies", fetchedCompanies);
        console.log("myDebug validInputtedData", validInputtedData);
        console.log("myInputtedData", inputtedData);
        updateLeadState({
          searchingFor: "PEOPLE",
          currentSearchPayload: validInputtedData,
          valueLabels: {
            ...valueLabels,
            ...Object.fromEntries(
              fetchedCompanies.map((company: any) => [
                company?.organization_id ??
                  company.owned_by_organization_id ??
                  (company?.id || company?.value),
                company.name || company?.company_name || company?.label,
              ]),
            ),
          },
        });
        await searchPeopleMutateAsync({
          payload: validInputtedData,
          isUseMixrank: isUseMixRank,
        });
      } catch (error) {
        console.log("myDebug error", error);
      }
      //TODO: Fix this
      // if (isForAISDR) {
      //   await campaignDetailsMutateAsync({
      //     campaignId: selectedCampaign,
      //     leadPreferences: validInputtedData,
      //   });
      // }
    },
    [
      searchPeopleMutateAsync,
      updateLeadState,
      toast,
      fetchedCompanies,
      valueLabels,
      isUseMixRank,
    ],
  );
  useEffect(() => {
    if (!applyFilterData) return;
    handleSearch(inputtedData);
    updateLeadState({ applyFilterData: false });
  }, [applyFilterData, inputtedData, handleSearch]);

  useEffect(() => {
    const peopleSearchPayload =
      window.peopleSearchPayload ?? _peopleSearchPayload;
    if (!peopleSearchPayload || companySizesLoading) return;
    try {
      let newInputData = {
        search_text: "",
        person_titles: [],
        person_not_titles: [],
        person_past_titles: [],
        person_seniorities: [],
        q_person_name: "",
        organization_ids: [], // array of company ids
        not_organization_ids: [], // array of company ids
        person_past_organization_ids: [], // array of company ids
        organization_industry_tag_ids: [], // array of industry ids
        organization_num_employees_ranges: [], // array of employee range ["20001,5000"],
        q_organization_keyword_tags: [], // array of company tags
        q_not_organization_keyword_tags: [], // array of company tags
        person_locations: [], // array of locations
        person_not_locations: [], // array of locations
        person_location_name: "", // string of zip (if location radius selected)
        person_location_radius: "", // string of radius (if location radius selected)
        in_house_organizations: [],
        in_house_organizations_not: [],
        in_house_organizations_past: [],
        educations: [],
        languages: [],
        seniorities: [],
        skills: [],
        certifications: [],
        followers: "",
        min_num_connection: null,
        min_month_in_current_role: null,
        max_month_in_current_role: null,

        employee_count: "",
        q_exclude_keywords: "",
        q_keywords: "",
        company_sizes: [],
        company_types: [],
        included_industry_codes: [],
        excluded_industry_codes: [],
      } as any;
      console.log("peopleSearchPayload", peopleSearchPayload);
      const allFetchedCompanies = _.uniqBy(
        [
          ...fetchedCompanies,
          ...(peopleSearchPayload.fetchedCompanies || []),
          ...(peopleSearchPayload.excludeFetchedCompanies || []),
          ...(peopleSearchPayload.pastFetchedCompanies || []),
        ],
        (company: any) =>
          company?.organization_id ??
          company?.owned_by_organization_id ??
          company?.id,
      );
      if (allFetchedCompanies.length > 0) {
        setFetchedCompanies(allFetchedCompanies);

        newInputData = {
          ...newInputData,
          ...(isUseMixRank
            ? {
                in_house_organizations: (
                  peopleSearchPayload.fetchedCompanies?.slice(0, 1) || []
                ).map((company: any) => ({
                  label: company.company_name,
                  value: company.id,
                })),
                in_house_organizations_not: (
                  peopleSearchPayload.excludeFetchedCompanies?.slice(0, 1) || []
                ).map((company: any) => ({
                  label: company.company_name,
                  value: company.id,
                })),
                in_house_organizations_past: (
                  peopleSearchPayload.pastFetchedCompanies?.slice(0, 1) || []
                ).map((company: any) => ({
                  label: company.company_name,
                  value: company.id,
                })),
              }
            : {
                organization_ids:
                  peopleSearchPayload.fetchedCompanies?.slice(0, 1) || [],
                not_organization_ids:
                  peopleSearchPayload.excludeFetchedCompanies?.slice(0, 1) ||
                  [],
                person_past_organization_ids:
                  peopleSearchPayload.pastFetchedCompanies?.slice(0, 1) || [],
              }),
        };
      }

      /* 
        
        q_exclude_keywords
        q_keywords
      */

      Object.entries(peopleSearchPayload).forEach(([key, value]: any) => {
        if (
          [
            "organization_num_employees_ranges",
            "skills",
            "certifications",
            "languages",
          ].includes(key)
        ) {
          newInputData[key] =
            value instanceof Array
              ? value?.map((item: any) => ({
                  name: item.replace(",", "-"),
                }))
              : typeof value === "string"
                ? [value]
                : [];
        } else {
          newInputData[key] =
            value instanceof Array
              ? value?.map((item: any) => ({
                  label: item,
                  value: item,
                }))
              : value;
        }
      });

      if (peopleSearchPayload.followers) {
        const followers = peopleSearchPayload.followers.split(":");
        newInputData = {
          ...newInputData,
          followers: followers[1],
          isMoreFollowers: followers[0] === "gt",
          isLessFollowers: followers[0] === "lt",
        };
      }

      if (peopleSearchPayload.employee_count) {
        const employeeCount = peopleSearchPayload.employee_count.split(":");
        newInputData = {
          ...newInputData,
          followers: employeeCount[1],
          isMoreEmployees: employeeCount[0] === "gt",
          isLessEmployees: employeeCount[0] === "lt",
        };
      }

      const newState = {
        ...newInputData,
      };

      setInputtedData(newState);

      if (peopleSearchPayload.company_sizes) {
        newState.company_sizes = peopleSearchPayload.company_sizes.map(
          (company_size: string) => {
            return companySizesData?.data?.find(
              (industry: Option) => industry.label === company_size,
            );
          },
        );
      }

      if (peopleSearchPayload.company_types) {
        newState.company_types = peopleSearchPayload.company_types.map(
          (company_type: string) => {
            return companyTypesData?.data?.find(
              (industry: Option) => industry.label === company_type,
            );
          },
        );
      }

      if (peopleSearchPayload.included_industry_codes?.length) {
        newState.included_industry_codes =
          peopleSearchPayload.included_industry_codes.map(
            (industy_code: string) => {
              return industriesData?.data?.find(
                (industry: Option) => industry.label === industy_code,
              );
            },
          );
      }

      if (peopleSearchPayload.excluded_industry_codes) {
        newState.excluded_industry_codes =
          peopleSearchPayload.excluded_industry_codes.map(
            (industy_code: string) => {
              return industriesData?.data?.find(
                (industry: Option) => industry.label === industy_code,
              );
            },
          );
      }

      console.log("myDebug valueLabels", valueLabels);

      handleSearch(newState);

      window.peopleSearchPayload = null;
      updateLeadState({
        peopleSearchPayload: null,
        appliedPeopleSearchPayload: peopleSearchPayload,
        valueLabels: {
          ...valueLabels,
          ...Object.fromEntries(
            allFetchedCompanies.map((company: any) => [
              company?.organization_id || company?.id || company?.value,
              company?.name || company?.company_name || company?.label,
            ]),
          ),
        },
      });
    } catch (error) {
      console.error(error, "error in sidebar", _peopleSearchPayload);
      toast({
        title: "Error",
        description: "An error occurred",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    }
  }, [
    handleSearch,
    _peopleSearchPayload,
    companySizesData,
    companySizesLoading,
    companyTypesData,
    industriesData,
  ]);

  return (
    <div
      className={`flex size-full h-full flex-col justify-between ${
        isForAutoPilot || isForAISDR ? "" : "max-h-screen"
      }`}
    >
      <div
        className={`grow overflow-y-auto bg-white ${isForAutoPilot || isForAISDR ? "h-full" : "h-[5rem]"}`}
      >
        <>
          {/* <FilterComponent className="px-2" /> */}
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={JobTitleIcon} />}>
                Job Titles
              </AccordionButton>
            }
            numOfAppliedFilters={
              (inputtedData?.person_titles?.length || 0) +
              (inputtedData?.person_not_titles?.length || 0) +
              (inputtedData?.person_past_titles?.length || 0)
            }
          >
            <div className="space-y-3">
              <div id={genId("person_titles")}>
                <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. CEO, CTO"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_titles}
                />
              </div>
              <div id={genId("person_not_titles")}>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to exclude
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_not_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_not_titles}
                />
              </div>
              <div id={genId("person_past_titles")}>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Past Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_past_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_past_titles}
                />
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton icon={<img src={CompanyIcon} />}>
                Company
              </AccordionButton>
            }
            numOfAppliedFilters={
              (inputtedData?.organization_ids?.length || 0) +
              (inputtedData?.not_organization_ids?.length || 0) +
              (inputtedData?.person_past_organization_ids?.length || 0) +
              (inputtedData?.q_organization_keyword_tags?.length || 0) +
              (inputtedData?.q_not_organization_keyword_tags?.length || 0)
            }
          >
            <div className="space-y-3">
              {isUseMixRank ? (
                <>
                  <CompanyNameSearches
                    label="Companies to include"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations", value },
                        inputtedData,
                        setInputtedData,
                      );
                      setFetchedCompanies(value);
                    }}
                    value={inputtedData?.in_house_organizations}
                  />
                  <CompanyNameSearches
                    label="Companies to exclude"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations_not", value },
                        inputtedData,
                        setInputtedData,
                      );
                      setFetchedCompanies(value);
                    }}
                    value={inputtedData?.in_house_organizations_not}
                  />
                  <CompanyNameSearches
                    label="Past Companies to include"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations_past", value },
                        inputtedData,
                        setInputtedData,
                      );
                      setFetchedCompanies(value);
                    }}
                    value={inputtedData?.in_house_organizations_past}
                  />
                </>
              ) : (
                <>
                  <PersanaInput
                    id={genId("organization_ids")}
                    value={inputtedData?.organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"organization_ids"}
                    label={"Companies to include"}
                    showDropdown
                    isDynamic
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      console.log("target", target);

                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("not_organization_ids")}
                    value={inputtedData?.not_organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"not_organization_ids"}
                    label={"Companies to exclude"}
                    showDropdown
                    isDynamic
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("person_past_organization_ids")}
                    value={inputtedData?.person_past_organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"person_past_organization_ids"}
                    label={"Past Companies to include"}
                    showDropdown
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                </>
              )}
              {!isUseMixRank && (
                <PersanaInput
                  id={genId("organization_num_employees_ranges")}
                  value={inputtedData?.organization_num_employees_ranges}
                  placeholder={"e.g. 11-20 , 101-200"}
                  name={"organization_num_employees_ranges"}
                  label={"Number of Employees"}
                  showDropdown={false}
                  disabledCustomSelect
                  arrayOfValues={apolloCompanySize}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
              )}

              <PersanaInput
                value={inputtedData?.employee_count}
                optionComponent={
                  <div className="flex items-center gap-x-3">
                    <RadioButton
                      label={"Greater than"}
                      name={"isMoreEmployees"}
                      checked={inputtedData?.isMoreEmployees}
                      setChecked={(e) => {
                        setInputtedData({
                          ...inputtedData,
                          isMoreEmployees: e.target.checked,
                          isLessFollowers: !e.target.checked,
                        });
                      }}
                    />
                    <RadioButton
                      label={"Less than"}
                      name={"isLessEmployees"}
                      checked={inputtedData?.isLessEmployees}
                      setChecked={(e) => {
                        setInputtedData({
                          ...inputtedData,
                          isLessEmployees: e.target.checked,
                          isMoreEmployees: !e.target.checked,
                        });
                      }}
                    />
                  </div>
                }
                placeholder={"e.g. 1000"}
                name={"employee_count"}
                label="Number of Employees"
                type="number"
                onChange={(e) => {
                  setInputtedData({
                    ...inputtedData,
                    employee_count: e.target.value,
                  });
                }}
              />

              <CompanyTypes
                value={inputtedData.company_types}
                onChange={(selectedValues) => {
                  handlePushToArray(
                    { name: "company_types", value: selectedValues },
                    inputtedData,
                    setInputtedData,
                  );
                }}
              />

              <CompanySizes
                value={inputtedData.company_sizes as unknown as Option}
                onChange={(selectedValues) => {
                  handlePushToArray(
                    { name: "company_sizes", value: selectedValues },
                    inputtedData,
                    setInputtedData,
                  );
                }}
              />

              <PersanaInput
                id={genId("q_keywords")}
                value={inputtedData?.q_keywords}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_keywords"}
                label={"Keywords to Include (description)"}
                onChange={(e) => {
                  setInputtedData({
                    ...inputtedData,
                    q_keywords: e.target.value,
                  });
                }}
              />
              <PersanaInput
                id={genId("q_exclude_keywords")}
                value={inputtedData?.q_exclude_keywords}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_exclude_keywords"}
                label={"Keywords to exclude (description)"}
                onChange={(e) => {
                  setInputtedData({
                    ...inputtedData,
                    q_exclude_keywords: e.target.value,
                  });
                }}
              />
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton icon={<img src={LocationIcon} />}>
                Location
              </AccordionButton>
            }
            numOfAppliedFilters={
              (inputtedData?.person_locations?.length || 0) +
              (inputtedData?.person_not_locations?.length || 0)
            }
          >
            <div className="space-y-3">
              <div id={genId("person_locations")}>
                {isUseMixRank ? (
                  <Countries
                    label="Country to Include"
                    value={inputtedData?.person_locations}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "person_locations", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                ) : (
                  <>
                    <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                      City / State / Country or Any Location
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. San Francisco, United States"
                      options={extractNameAndContinent(countries)}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          { name: "person_locations", value: selectedValues },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.person_locations}
                    />
                  </>
                )}
              </div>
              <div id={genId("person_not_locations")}>
                {isUseMixRank ? (
                  <Countries
                    label="Country to Exclude"
                    value={inputtedData?.person_not_locations}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "person_not_locations", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                ) : (
                  <>
                    <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                      Location to exclude
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. San Francisco, United States"
                      options={extractNameAndContinent(countries)}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          {
                            name: "person_not_locations",
                            value: selectedValues,
                          },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.person_not_locations}
                    />
                  </>
                )}
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton icon={<img src={NameIcon} />}>
                Profile
              </AccordionButton>
            }
            numOfAppliedFilters={
              (inputtedData?.q_person_name ? 1 : 0) +
              (inputtedData?.educations?.length || 0) +
              (inputtedData?.languages?.length || 0) +
              (inputtedData?.skills?.length || 0) +
              (inputtedData?.certifications?.length || 0) +
              (inputtedData?.followers ? 1 : 0) +
              (inputtedData?.min_num_connection ? 1 : 0) +
              (inputtedData?.min_month_in_current_role ? 1 : 0) +
              (inputtedData?.max_month_in_current_role ? 1 : 0) +
              (inputtedData?.seniorities?.length || 0)
            }
          >
            <div className="space-y-3">
              <SearchPersanaInput
                type="text"
                name="q_person_name"
                id={genId("q_person_name")}
                placeholder="e.g. John Doe"
                value={inputtedData?.q_person_name}
                title="Person Name"
                onChange={(e) => {
                  setInputtedData({
                    ...inputtedData,
                    q_person_name: e.target.value,
                  });
                }}
              />
              {isUseMixRank ? (
                <>
                  <Educations
                    inputtedData={inputtedData}
                    onClick={(target) => {
                      handlePushToArray(
                        { name: "educations", value: target },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                  <div>
                    <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
                      Languages
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. English"
                      options={[]}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          { name: "languages", value: selectedValues },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.languages}
                    />
                  </div>

                  <div>
                    <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
                      Skills
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. Microsoft Office"
                      options={[]}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          { name: "skills", value: selectedValues },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.skills}
                    />
                  </div>

                  <div>
                    <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
                      Certifications
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. BLSD"
                      options={[]}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          { name: "certifications", value: selectedValues },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.certifications}
                    />
                  </div>

                  <SearchPersanaInput
                    type="number"
                    name="followers"
                    id={genId("followers")}
                    placeholder="e.g. 1000"
                    value={inputtedData?.followers}
                    title="Followers"
                    onChange={(e) => {
                      setInputtedData({
                        ...inputtedData,
                        followers: e.target.value,
                      });
                    }}
                    optionComponent={
                      <div className="flex items-center gap-x-3">
                        <RadioButton
                          label={"Greater than"}
                          name={"isMoreFollowers"}
                          checked={inputtedData?.isMoreFollowers}
                          setChecked={(e) => {
                            setInputtedData({
                              ...inputtedData,
                              isMoreFollowers: e.target.checked,
                              isLessFollowers: !e.target.checked,
                            });
                          }}
                        />
                        <RadioButton
                          label={"Less than"}
                          name={"isLessFollowers"}
                          checked={inputtedData?.isLessFollowers}
                          setChecked={(e) => {
                            setInputtedData({
                              ...inputtedData,
                              isLessFollowers: e.target.checked,
                              isMoreFollowers: !e.target.checked,
                            });
                          }}
                        />
                      </div>
                    }
                  />

                  <SearchPersanaInput
                    type="number"
                    name="min_num_connection"
                    id={genId("min_num_connection")}
                    placeholder="e.g. 100"
                    value={inputtedData?.min_num_connection as any}
                    title="Minimum Number of Connections"
                    onChange={(e) => {
                      setInputtedData({
                        ...inputtedData,
                        min_num_connection: e.target.value as any,
                      });
                    }}
                  />

                  <SearchPersanaInput
                    type="number"
                    name="min_month_in_current_role"
                    id={genId("min_month_in_current_role")}
                    placeholder="e.g. 2"
                    value={inputtedData?.min_month_in_current_role as any}
                    title="Minimum Months in Current Role"
                    onChange={(e) => {
                      setInputtedData({
                        ...inputtedData,
                        min_month_in_current_role: e.target.value as any,
                      });
                    }}
                  />

                  <SearchPersanaInput
                    type="number"
                    name="max_month_in_current_role"
                    id={genId("max_month_in_current_role")}
                    placeholder="e.g. 24"
                    value={inputtedData?.max_month_in_current_role as any}
                    title="Maximum Months in Current Role"
                    onChange={(e) => {
                      setInputtedData({
                        ...inputtedData,
                        max_month_in_current_role: e.target.value as any,
                      });
                    }}
                  />

                  <Seniorities
                    value={inputtedData?.seniorities}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "seniorities", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                </>
              ) : null}
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton
                icon={<Icons.IndustryIcon className="text-lg" color="grey" />}
              >
                Industries
              </AccordionButton>
            }
          >
            <Industries
              isCreatable={false}
              value={inputtedData.included_industry_codes as unknown as Option}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "included_industry_codes", value: selectedValues },
                  inputtedData,
                  setInputtedData,
                );
              }}
              label="Industries to include"
            />
            <Industries
              isCreatable={false}
              value={inputtedData.excluded_industry_codes as unknown as Option}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "excluded_industry_codes", value: selectedValues },
                  inputtedData,
                  setInputtedData,
                );
              }}
              label="Industries to exclude"
            />
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton icon={<Icons.SearchIcon />}>
                Keywords on Profile
              </AccordionButton>
            }
            showBottomBorder
            numOfAppliedFilters={inputtedData?.search_text ? 1 : 0}
          >
            <InputGroup id={genId("q_keywords")} className="rounded-md">
              <InputLeftElement pointerEvents="none">
                <Icons.SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                rounded="md"
                placeholder="Search People..."
                value={inputtedData.search_text}
                onChange={(e) => {
                  handlePushToArray(
                    { name: "search_text", value: e.target.value },
                    inputtedData,
                    setInputtedData,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(inputtedData);
                  }
                }}
              />
            </InputGroup>
          </SearchInputAccordion>
        </>
      </div>
      {!isForAutoPilot && (
        <ButtonGroup className="!bg-white p-4 !font-title">
          <Button
            isDisabled={totalFilters <= 0}
            isLoading={searchingFor === "PEOPLE"}
            id={generateId("persana-search-button")}
            loadingText="Searching"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
            onClick={() => {
              handleSearch(inputtedData);
            }}
          >
            Search
          </Button>
          {!isForAISDR && (
            <IconButton
              colorScheme="gray"
              isDisabled={totalFilters <= 0}
              aria-label="Save Filters"
              icon={<Icons.SaveIcon />}
              onClick={() => {
                saveFilterModalDisclosure.onOpen();
              }}
            />
          )}
        </ButtonGroup>
      )}
      <SaveCurrentFilter disclosure={saveFilterModalDisclosure} />
    </div>
  );
};

const Sidebar = ({
  isForAutoPilot,
  searchingFor,
  isForAISDR,
}: {
  isForAutoPilot?: boolean;
  searchingFor?: string;
  isForAISDR?: boolean;
}) => {
  const searchingFor2 = useSearchLeadsStore((state) => state.searchingFor);

  console.log("mySearchingFor 1", searchingFor);
  console.log("mySearchingFor 1a", searchingFor2);

  return (
    <ControlledTabs size={"sm"} className="size-full bg-white">
      <TabList borderBottom="1px solid #F0EFF1">
        {!isForAutoPilot && !isForAISDR ? (
          <>
            <Tab isDisabled={!!searchingFor}>People</Tab>
            <Tab isDisabled={!!searchingFor}> Company</Tab>
            <Tab isDisabled={!!searchingFor}>Saved Search</Tab>
          </>
        ) : null}
      </TabList>
      <TabPanels className="size-full">
        <TabPanel className="h-full !p-0">
          <SidebarComponent
            isForAutoPilot={isForAutoPilot}
            isForAISDR={isForAISDR}
          />
        </TabPanel>
        <TabPanel className="h-full !p-0">
          <CompanySidebarComponent isForAutoPilot={isForAutoPilot} />
        </TabPanel>
        <TabPanel>
          <SavedSearchesUI />
        </TabPanel>
      </TabPanels>
    </ControlledTabs>
  );
};
export default Sidebar;
