const LeadStatus = ({ status }: { status: string }) => {
  return (
    <div className="h-full flex items-center">
      <div
        className={`!h-[24px] text-[12px] font-medium w-fit px-[12px] rounded-[16px] flex items-center justify-center ${status === "PAUSED" ? "bg-[#FFF9EA] text-[#FF791C]" : status === "STARTED" || status === "COMPLETED" ? "bg-[#EBFFEF] text-[#00B41D]" : status === "INPROGRESS" ? "bg-[#FFF9EA] text-[#FF791C]" : "bg-[#FFEAE9] text-[#CC0C00]"}`}
      >
        {status}
      </div>
    </div>
  );
};

export default LeadStatus;
