import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import CreateCampaignImg from "@/assets/svgs/create-campaign.svg";
import Icons from "../Icons";
import useCreateCampaign from "./hooks/useCreateCampaign";
import { useNavigate } from "@tanstack/react-router";
import { queryClient } from "@/lib/queryClient";

const CreateCampaignModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [campaignName, setCampaignName] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const createCampaignMutation = useCreateCampaign();

  const handleSubmit = () => {
    if (!campaignName) {
      toast({
        title: "Campaign name is required",
        status: "error",
        duration: 2000,
        position: "top-right",
      });
      return;
    }

    createCampaignMutation.mutate(
      { name: campaignName },
      {
        onSuccess: async (result) => {
          if (result.success) {
            toast({
              title: "Campaign created successfully",
              status: "success",
              duration: 2000,
              position: "top-right",
            });

            await queryClient.invalidateQueries({
              queryKey: ["campaignsList"],
            });

            navigate({
              to: "/campaigns/$campaignId",
              // @ts-ignore
              params: { campaignId: result?.data?.data?.id },
            });
            setCampaignName("");
            onClose();
          } else {
            toast({
              title: "Error creating Campaign",
              status: "error",
              duration: 2000,
              position: "top-right",
            });
          }
        },
        onError: () => {
          toast({
            title: "Error creating Campaign",
            status: "error",
            duration: 2000,
            position: "top-right",
          });
        },
      },
    );
  };

  return (
    <>
      <Button
        leftIcon={<Icons.PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        Create a Nia Campaign
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={"2xl"}
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="!p-0">
            <div className="w-full h-auto flex flex-col items-center justify-center gap-2 bg-[#F5F3FF] py-[30px]">
              <img src={CreateCampaignImg} alt="" />
              <p className="text-[16px] font-medium text-[#000000]">
                Create A Campaign
              </p>
              <p className="text-[13px] text-[#0F0D1C] font-[400]">
                Let’s get started with Campaign Automation
              </p>
            </div>
          </ModalHeader>

          <ModalBody className="mt-6">
            <FormControl id="campaignName">
              <FormLabel>
                <p className="text-[15px]">Enter Campaign Name</p>
                <Input
                  className="mt-2"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
              </FormLabel>
            </FormControl>
          </ModalBody>

          <ModalFooter className="!flex !items-center !justify-center">
            <Button
              colorScheme="purple"
              mr={3}
              onClick={handleSubmit}
              isLoading={createCampaignMutation.isPending}
              isDisabled={!campaignName}
            >
              Start Campaign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCampaignModal;
