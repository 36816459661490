import campaignService from "@/services/campaign.service";
import { useQuery } from "@tanstack/react-query";

const useLeadList = (id: number) => {
  return useQuery({
    queryKey: ["leadList", id],
    queryFn: async () => campaignService.getCampaignLeads(id),
  });
};

export default useLeadList;
