import { FilterType } from "@/types/table.types";

export const findPlaceholderValues = (text: string) => {
  const regex = /{{(.*?)}}/g;
  const matches = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1]);
  }
  return matches;
};

export const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
};

export const generateFilterPayload = (filter: FilterType[]) => {
  const filterPayload = filter.map((item) => {
    const { column } = item;
    return {
      ...item,
      column: {
        _id: column._id,
        name: column.name,
      },
    };
  });
  const isAnyEmpty = filterPayload.some(
    (item) => item.condition.hasInput && !item.value,
  );

  return {
    filterPayload,
    isAnyEmpty,
  };
};

export function arrayToObject(array: any[]) {
  const obj: { [key: string]: any } = {}; // Specify the type of obj as an indexable object
  array.forEach((item) => {
    const key = item.name.replace(/\s+/g, "_"); // Replace spaces with underscores
    obj[key] = item.value;
  });
  return obj;
}

export const downloadFile = ({
  url,
  fileName = "table.csv",
}: {
  url: string;
  fileName?: string;
}) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const transformCustomFieldsObject = (inputObj: any) => {
  const outputArray = [] as any;

  const keys = Object.keys(inputObj);
  if (!keys?.length) return [];

  keys?.forEach((key, index) => {
    const transformedEntry = {
      id: `custom_field_name_${index + 1}`,
      name: key.replace(/_/g, " "),
      value: inputObj[key],
    };

    outputArray.push(transformedEntry);
  });

  return outputArray;
};

export const getCurrentDateInYYYYMM = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;
  return `${year}-${formattedMonth}`;
};

export const isValidAlphanumericOrUnderscore = (input: string): boolean => {
  const regex = /^[a-zA-Z0-9_]+$/;
  return regex.test(input);
};

export const formatNumber = (num: number): string => {
  if (num >= 1_000_000_000_000) {
    return `${(num / 1_000_000_000_000).toFixed(1)}T`;
  } else if (num >= 1_000_000_000) {
    return `${(num / 1_000_000_000).toFixed(1)}B`;
  } else if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1)}M`;
  } else if (num >= 1_000) {
    return `${(num / 1_000).toFixed(1)}k`;
  } else {
    return num.toString();
  }
};
export const colorList = [
  "#D8A6DD",
  "#E4ADA9",
  "#FCF8D9",
  "#F3DBB1",
  "#EDA6E7",
  "#D0BFA9",
  "#C8A7FB",
  "#EAD1AE",
  "#EAB2F2",
  "#D6F6BE",
  "#D6DAEC",
  "#EEE8D5",
  "#FC99A2",
  "#E1ECF5",
  "#E9DCF6",
  "#D0E8B8",
  "#E9DAC2",
  "#DCE2AA",
  "#F9C0DC",
  "#D7B3FC",
  "#D2DCEB",
  "#F0D4DE",
  "#CAE0F4",
  "#F9D8D2",
  "#E6BACF",
  "#F4FDBE",
  "#ECA9FD",
  "#D6A0FC",
  "#CDF5AB",
  "#EBA5F4",
  "#D9B8EC",
  "#D8ECC7",
  "#E2F8E7",
  "#D2B1E6",
  "#DFE3EE",
  "#DCB7FD",
  "#F5FBF4",
  "#D99ABF",
  "#C9CFA1",
  "#D9F0A7",
  "#EBD1AE",
  "#DAFDAE",
  "#D3F9ED",
  "#DBDEBF",
  "#FFB4D3",
  "#D6E0B8",
  "#D8E7F7",
  "#CAF8D0",
  "#FAF4FF",
  "#E7ABFB",
];
export function isValidLinkedInUrl(url: string) {
  const regex = /^https?:\/\/(www\.)?linkedin\.com\/in\/[^/?]+\/?$/;
  return regex.test(url);
}

export function isValidEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const stripHtmlTags = (htmlString: string) => {
  // Replace &nbsp; with a newline character
  const formattedString = htmlString.replace(/&nbsp;/g, "\n");

  // Create a temporary DOM element to parse the HTML
  const tempElement = document.createElement("div");
  tempElement.innerHTML = formattedString;

  return tempElement.textContent || tempElement.innerText || "";
};
