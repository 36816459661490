import { Box } from "@chakra-ui/react";
import LoaderImg from "@/assets/svgs/campaign-loader.svg";

const CampaignLoader = () => {
  return (
    <div className="flex items-center justify-center !h-screen w-full">
      <Box className="flex flex-col gap-2 items-center w-[490px] py-[24px] px-[20px] bg-white rounded-[20px] shadow-md">
        <img src={LoaderImg} alt="" />
        <p className="text-[20px] text-[#000000]">Loading...</p>
        <p className="text-[#373F51] text-[14px]">
          Be patient. You're in good hands...
        </p>
      </Box>
    </div>
  );
};

export default CampaignLoader;
