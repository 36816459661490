import Icons from "@/components/Icons";
import { Box, Text } from "@chakra-ui/react";

const DealValue = () => {
  return (
    <Box className="w-[40%] bg-white rounded-lg p-4 border-[#F0EFF1] !shadow-lg h-[220px]">
      <div>
        <div className="flex items-center gap-2 mb-6">
          <Icons.InformationIcon className="text-[20px] text-[#5024DC]" />
          <Text className="text-[16px] text-[#373F51] font-medium">
            Total Deal Value
          </Text>
        </div>
        <p className="text-[48px] text-[#212120] font-medium">$0</p>
        <p className="text-[13px] text-[#7E889F] mt-1">
          This is the total value of deals made on this campaign.
        </p>
      </div>
    </Box>
  );
};

export default DealValue;
