import Icons from "@/components/Icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const LeadQuality = ({ campaignAnalytics }: { campaignAnalytics: any }) => {
  const data = {
    labels: ["Lead Bounced", "Lead Unsubscribed", "Lead Reached"],
    datasets: [
      {
        data: [
          campaignAnalytics?.bounce_count || 0,
          campaignAnalytics?.unsubscribed_count || 0,
          campaignAnalytics?.sent_count || 0,
        ],
        backgroundColor: ["#5024DC", "#BD90F4", "#DDD8FF"],
        borderColor: ["#5024DC", "#BD90F4", "#DDD8FF"],
        borderWidth: 1,
      },
    ],
  };

  // Check if all data items are 0
  const isEmptyState = data.datasets[0].data.every(
    (value) => Number(value) === 0,
  );

  // Chart options configuration
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderRadius: 4,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            return `${tooltipItem.label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <Box className="w-[40%] bg-white rounded-lg px-4 py-4 border-[#F0EFF1] !shadow-lg">
      <div>
        <div className="flex items-center gap-2">
          <Icons.InformationIcon className="text-[20px] text-[#5024DC]" />
          <Text className="text-[16px] text-[#373F51] font-medium">
            Lead Quality
          </Text>
        </div>
      </div>
      <Box className="flex justify-center items-center !w-full mt-4 relative h-[350px]">
        {isEmptyState ? (
          <Flex direction="column" align="center">
            <Text className="text-[16px] text-[#373F51] font-medium mb-2 text-center">
              No Data Available
            </Text>
            <Text className="text-[12px] text-[#A0A0A0] text-center">
              Please provide data to view the chart
            </Text>
          </Flex>
        ) : (
          <Doughnut data={data} options={options} />
        )}
      </Box>
    </Box>
  );
};

export default LeadQuality;
