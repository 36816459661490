import FindLeadsAutomations from "@/components/AutoPilotLeadsWorkflow/Automations";
import TailwindModalLayout from "../../TailwindModalLayout";

import DoubleStartIcon from "@/assets/svgs/doubleStar.svg";
import FindPeopleSource from "@/components/AutoPilotLeadsWorkflow/FindPeopleSource";
import { Button } from "@chakra-ui/react";
import { create } from "zustand";
import { TableData } from "@/types/table.types";
import JobOpeningsSource from "@/components/AutoPilotGoogleJobsWorkflow/JobOpeningsSourceNode/JobOpeningsSource";
import FineActiveHiringJobs from "@/components/AutoPilotJobsWorkflow/JobOpeningsSourceNode/FineActiveHiringJobs";
import MultipleSouceFindJobInputs from "@/components/AutoPilotMultiSourceJobsWorkflow/JobOpeningsSourceNode/MultipleSouceFindJobInputs";
import FindJobsWithPersanaInputs from "@/components/AutoPilotPersanaJobsWorkflow/JobOpeningsSourceNode/FindJobsWithPersanaInputs";

export type WalkThroughModalActionType = "new" | "update";
interface WalkThroughModalState {
  isOpen: boolean;
  title: string;
  flowName: string;
  currentStep: number;
  action?: WalkThroughModalActionType;
  selectedTableData?: Partial<TableData> | null;
  openModal: () => void;
  closeModal: () => void;
  updateModalState: (
    newState: Partial<
      Omit<
        WalkThroughModalState,
        "openModal" | "closeModal" | "updateModalState"
      >
    >,
  ) => void;
}

export const useWalkThroughModalStore = create<WalkThroughModalState>(
  (set) => ({
    isOpen: false,
    title: "",
    flowName: "",
    currentStep: 0,
    action: "new",
    selectedTableData: null,
    openModal: () => set({ isOpen: true }),
    closeModal: () =>
      set({
        isOpen: false,
        title: "",
        flowName: "",
        selectedTableData: null,
        action: "new",
        currentStep: 0,
      }),
    updateModalState: (newState) => set((state) => ({ ...state, ...newState })),
  }),
);

function WalkThroughModal() {
  const {
    isOpen,
    title,
    flowName,
    currentStep,
    action,
    selectedTableData,
    closeModal,
    updateModalState,
  } = useWalkThroughModalStore();

  const handleModalClose = () => {
    closeModal();
  };

  const walkFlows: {
    [key: string]: { title: string; component: React.ComponentType<any> }[];
  } = {
    find_new_leads: [
      {
        title: "Preferences",
        component: FindPeopleSource,
      },
      {
        title: "Automations",
        component: FindLeadsAutomations,
      },
    ],
    google_job_openings: [
      {
        title: "Job Openings Source",
        component: JobOpeningsSource,
      },
    ],
    find_active_hiring_jobs: [
      {
        title: "Job Openings Source",
        component: FineActiveHiringJobs,
      },
    ],
    find_active_hiring_jobs_multi_source: [
      {
        title: "Job Openings Source",
        component: MultipleSouceFindJobInputs,
      },
    ],
    find_job_openings_persana: [
      {
        title: "Job Openings Source",
        component: FindJobsWithPersanaInputs,
      },
    ],
  };

  const currentFlow = walkFlows[flowName] || [];

  const CurrentComponent = currentFlow[currentStep]?.component;

  return (
    <TailwindModalLayout
      modalOpen={isOpen}
      setModalOpen={handleModalClose}
      closeOnOuterClick
      // overlayClassName="bg-[rgba(0,_0,_0,_0.18)]"
      dialogClassName="!z-[2000]"
    >
      <div className="relative inline-block h-full overflow-hidden p-4 text-left align-bottom font-title transition-all sm:w-full sm:max-w-[63rem] sm:align-middle">
        <div className="modalShadow w-full rounded-[1.3rem] bg-white">
          {/* Header */}
          <div className="relative overflow-hidden rounded-t-[1.3rem] bg-[#F5F3FF]">
            <div
              className="absolute left-[17%] top-[10%] size-[300px] -translate-y-full rotate-90 gap-0 rounded-full"
              style={{
                background:
                  "linear-gradient(124.15deg, #C870F1 43.48%, #904CE7 65.32%, #5024DC 90.27%)",
                backdropFilter: "blur(100px)",
                filter: "blur(100px)",
              }}
            ></div>
            <div
              className="absolute right-[5%] top-[-3%] size-[300px] -translate-y-full rotate-90 gap-0 rounded-full"
              style={{
                background:
                  "linear-gradient(124.15deg, #C870F1 43.48%, #904CE7 65.32%, #5024DC 90.27%)",
                backdropFilter: "blur(130px)",
                filter: "blur(130px)",
              }}
            ></div>
            <div className="flex items-center justify-center py-7">
              <h3 className="relative w-fit text-lg">
                <span className="font-semibold">Autopilot:</span>{" "}
                <span>{title}</span>
                <span className="absolute right-[-7px] top-[-5px] flex translate-x-full items-center space-x-1 rounded-[0.47rem] bg-[#DDD8FF] px-2 py-1 text-sm font-medium">
                  <img src={DoubleStartIcon} alt="Double Start Icon" />
                  <span className="text-[#5024DC]">Trigger</span>
                </span>
              </h3>
            </div>
            {/* Steps */}
            <div className="flex w-full items-center space-x-6 border-y-[1.5px] border-[#F0EFF1] px-5">
              {currentFlow.map((step, index) => {
                const isCurrentStep = currentStep === index;

                return (
                  <button
                    key={`${step.title}-${index}`}
                    className={`flex items-center space-x-2 border-b-[2.2px] px-4 py-2 ${
                      isCurrentStep ? "border-[#5024DC]" : "border-transparent"
                    }`}
                    onClick={() => {
                      action === "new"
                        ? () => {}
                        : updateModalState({ currentStep: index });
                    }}
                  >
                    <span
                      className={`flex size-6 items-center justify-center  rounded-full font-medium text-white ${
                        isCurrentStep ? "bg-[#C03EDC]" : "bg-[#D0D4DC]"
                      }`}
                    >
                      {index + 1}
                    </span>
                    <span
                      className={`text-[1.1rem] font-medium ${
                        isCurrentStep ? "text-[#5024DC]" : "text-black"
                      }`}
                    >
                      {step.title}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>

          {CurrentComponent && (
            <CurrentComponent
              totalSteps={currentFlow.length}
              action={action}
              selectedTableData={selectedTableData}
            />
          )}
        </div>
      </div>
    </TailwindModalLayout>
  );
}

export default WalkThroughModal;

function WalkThroughModalBody({ children }: { children: React.ReactNode }) {
  return <div className="h-[55vh] overflow-y-auto">{children}</div>;
}

function WalkThroughModalFooter({
  isLoading,
  onSave,
  totalSteps,
  action,
}: {
  isLoading: boolean;
  onSave: () => Promise<void>;
  totalSteps: number;
  action?: WalkThroughModalActionType;
}) {
  const { closeModal, currentStep } = useWalkThroughModalStore();

  return (
    <div className="flex items-center justify-end space-x-4 rounded-b-[1.3rem] border-y border-[#F0EFF1] p-4 font-medium">
      <Button
        className="!rounded !border !border-[#C03EDC] !bg-transparent !px-4 !py-1.5"
        onClick={closeModal}
      >
        Close
      </Button>
      <Button
        isLoading={isLoading}
        className="!rounded !border !border-[#C03EDC] !bg-[#C03EDC] !px-4 !py-1 !text-white"
        onClick={async () => {
          await onSave();
          if (currentStep + 1 < totalSteps) {
            useWalkThroughModalStore
              .getState()
              .updateModalState({ currentStep: currentStep + 1 });
          } else {
            closeModal();
          }
        }}
      >
        {action === "new" ? "Save" : "Update"} &amp;{" "}
        {currentStep + 1 < totalSteps ? "Continue" : "Open Table"}
      </Button>
    </div>
  );
}

export { WalkThroughModalBody, WalkThroughModalFooter };
