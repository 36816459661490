import Icons from "@/components/Icons";
import { Box, Button, IconButton, Input } from "@chakra-ui/react";

const CoachingPoints = ({
  coachingPoints,
  handleAddCoachingPoint,
  handleDeleteCoachingPoint,
  handleInputChange,
}: {
  coachingPoints: string[];
  handleAddCoachingPoint: () => void;
  handleDeleteCoachingPoint: (index: number) => void;
  handleInputChange: (index: number, value: string) => void;
}) => {
  return (
    <>
      <div>
        <div className="flex items-center justify-between mb-2">
          <p className="text-[16px] text-[#0F0D1C] font-medium mb-2">
            Coaching Points
          </p>
          <Button
            className="!text-[13px] !font-medium !text-[#7E889F]"
            variant="outline"
            size="sm"
            leftIcon={<Icons.AddIcon />}
            onClick={handleAddCoachingPoint}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="mb-4 flex flex-col gap-3">
        {coachingPoints.map((point, index) => (
          <Box key={index} className="flex items-center gap-3">
            <Input
              value={point}
              size="md"
              className="!rounded-md"
              placeholder="Enter Coaching Point"
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
            <IconButton
              aria-label="delete"
              variant={"outline"}
              icon={<Icons.DeleteIcon className="text-[#7E889F]" />}
              size="sm"
              onClick={() => handleDeleteCoachingPoint(index)}
            />
          </Box>
        ))}
      </div>
    </>
  );
};

export default CoachingPoints;
