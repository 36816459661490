import { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ColDef, GridApi } from "ag-grid-community";
import Swal from "sweetalert2";

import CreateCampaignModal from "./CreateCampaignModal";
import Icons from "../Icons";
import useCampaignsList from "./hooks/useCampaignsList";
import useDeleteCampaign from "./hooks/useDeleteCampaign";
import { useNavigate } from "@tanstack/react-router";
import CampaignStatus from "../Common/Campaigns/CampaignStatus";
import { useMutation } from "@tanstack/react-query";
import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";
import CampaignLoader from "../Common/Campaigns/CampaignLoader";

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

const AllCampaignsGrid = () => {
  const {
    data: allCampaigns,
    isLoading,
    isFetching,
    refetch: refetchallCampaigns,
  } = useCampaignsList() as any;
  const navigate = useNavigate();
  const toast = useToast();
  const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const deleteCampaignMutation = useDeleteCampaign();
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    allCampaigns?.data || [],
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      campaignId,
      status,
    }: {
      campaignId: number;
      status: string;
    }) => campaignService.updateCampaign(campaignId, status),
  });

  const handleDelete = (id: number) => {
    Swal.fire({
      text: "Do you want to delete the campaign?",
      icon: "warning",
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      denyButtonText: "No",
      showCloseButton: true,
      preConfirm: async () => {
        deleteCampaignMutation.mutate(
          { id },
          {
            onSuccess: (result) => {
              if (result?.success) {
                toast({
                  title: "Campaign deleted successfully",
                  status: "success",
                  duration: 2000,
                  position: "top-right",
                });
              } else {
                toast({
                  title: "Error deleting Campaign",
                  status: "error",
                  duration: 2000,
                  position: "top-right",
                });
              }
            },

            onError: () => {
              toast({
                title: "Error deleting Campaign",
                status: "error",
                duration: 2000,
                position: "top-right",
              });
            },
          },
        );
      },
      preDeny: async () => {
        return;
      },
    });
  };
  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: "Campaign Name",
          field: "name",
          sortable: true,
          filter: false,
          width: 350,
          cellRenderer: (params: any) => (
            <p className="text-[14px] font-medium text-[#0F0D1C]">
              {params.value}
            </p>
          ),
        },
        // {
        //   headerName: "Lead Profiles",
        //   field: "",
        //   sortable: true,
        //   filter: false,
        //   width: 150,
        // },
        // {
        //   headerName: "Progress",
        //   field: "",
        //   sortable: true,
        //   filter: false,
        //   width: 180,
        // },
        {
          headerName: "Date Created",
          field: "created_at",
          sortable: true,
          filter: false,
          width: 250,
          cellRenderer: (params: any) => (
            <p className="text-[14px] font-medium text-[#0F0D1C]">
              {new Date(params.value).toLocaleString()}
            </p>
          ),
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          filter: false,
          width: 250,
          cellRenderer: (params: any) => (
            <CampaignStatus status={params.data.status} />
          ),
        },
        {
          headerName: "Actions",
          field: "",
          sortable: false,
          filter: false,
          width: 200,
          cellRenderer: (params: any) => (
            <ButtonGroup className="flex items-center">
              <IconButton
                className="mr-2 delete-btn"
                size="sm"
                bg={"#F5F3FF"}
                color={"#7E889F"}
                variant="outline"
                icon={<Icons.DeleteIcon />}
                isLoading={deleteCampaignMutation.isPending}
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleDelete(params?.data?.id);
                }}
                aria-label={"Delete Campaign"}
              />
            </ButtonGroup>
          ),
        },
        {
          headerName: "Activate",
          field: "",
          sortable: false,
          filter: false,
          width: 200,
          cellRenderer: (params: any) => (
            <Switch
              isChecked={params.data.status === "ACTIVE"}
              size="md"
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                mutateAsync(
                  {
                    campaignId: params.data.id,
                    status: e.target.checked ? "START" : "PAUSED",
                  },
                  {
                    onSuccess(response: any) {
                      if (response.success) {
                        toast({
                          title: "Campaign status updated successfully",
                          status: "success",
                          duration: 3000,
                          position: "top-right",
                          isClosable: true,
                        });
                        setTimeout(() => {
                          queryClient.refetchQueries({
                            queryKey: ["campaignsList"],
                          });
                        }, 500);
                      } else {
                        toast({
                          title: "Error",
                          description:
                            response?.error?.message ||
                            "Failed to update Campaign status",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                          position: "top-right",
                        });
                      }
                    },
                    onError(error) {
                      toast({
                        title:
                          error.message || "Failed to update Campaign status",
                        status: "success",
                        duration: 3000,
                        position: "top-right",
                        isClosable: true,
                      });
                    },
                  },
                );
              }}
              id={`source-switch`}
              className="text-[14px] flex font-medium activate-switch"
              colorScheme="purple"
            />
          ),
        },
      ] as ColDef<any>[],
    [],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const handleRowClick = (e: any) => {
    const isActionClick = e.event.target.closest(
      ".delete-btn, .activate-switch",
    );
    if (isActionClick) {
      return;
    }
    navigate({
      to: "/campaigns/$campaignId",
      params: { campaignId: e.data.id },
    });
  };

  useEffect(() => {
    setFilteredCampaigns(allCampaigns?.data || []);
  }, [allCampaigns]);

  useEffect(() => {
    if (isFetching || isPending) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    if (!allCampaigns?.data?.length) {
      gridApi?.showNoRowsOverlay();
    }
  }, [isFetching, isPending, gridApi, allCampaigns?.data ?? null]);

  return (
    <Box className="ag-theme-quartz mx-4">
      {isLoading ? (
        <div className="flex items-center justify-center !h-screen w-full">
          <CampaignLoader />
        </div>
      ) : (
        <>
          <Box className="flex flex-row items-center justify-between mb-2">
            <Text className="text-2xl font-[500]">Nia</Text>
            <ButtonGroup size="sm" className="my-2 !ml-auto">
              <CreateCampaignModal />
              <Button
                onClick={() => refetchallCampaigns()}
                isLoading={isFetching}
                isDisabled={isFetching}
                variant={"outline"}
                aria-label={"Reload"}
                leftIcon={<Icons.RefreshIcon />}
              >
                Refetch Campaigns
              </Button>
            </ButtonGroup>
          </Box>
          <Box>
            <InputGroup width="40%" className="mb-[20px]" size={"sm"}>
              <InputLeftElement>
                <Icons.SearchIcon size={16} color="gray" />
              </InputLeftElement>
              <Input
                style={{ borderRadius: "20px" }}
                placeholder="Search all campaigns"
                variant="outline"
                onChange={(e) => {
                  const query = e.target.value;
                  setSearchInput(query);
                  const filteredData = query
                    ? allCampaigns?.data?.filter((item: any) =>
                        item.name.toLowerCase().includes(query.toLowerCase()),
                      )
                    : allCampaigns?.data;
                  setFilteredCampaigns(filteredData);
                }}
                value={searchInput}
              />
            </InputGroup>
          </Box>
          <Box
            className="custom-scrollbar"
            overflow="auto"
            height="calc(100vh - 140px)"
          >
            <AgGridReact
              className="all-campaigns-grid"
              columnDefs={columnDefs}
              rowData={filteredCampaigns ?? []}
              rowSelection="multiple"
              suppressRowClickSelection
              domLayout="normal"
              onGridReady={onGridReady}
              onRowClicked={handleRowClick}
              rowStyle={{ cursor: "pointer" }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AllCampaignsGrid;
