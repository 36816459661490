import { Input, Textarea, Box, Text } from "@chakra-ui/react";

interface Props {
  profileInfo: {
    firstName: string;
    companyName: string;
    companyDescription: string;
    callToAction: string;
  };
  handleUpdateSettings: (fieldName: string, value: string) => void;
}

const Settings = ({ profileInfo, handleUpdateSettings }: Props) => {
  return (
    <div>
      <p className="text-[16px] text-[#7E889F] font-medium mb-2">
        Personal Information
      </p>
      <div className="p-[16px] rounded-md border border-[#F0EFF1]">
        {/* Your Name */}
        <Box>
          <Text fontSize="sm" mb={1} fontWeight={500}>
            Your Name
          </Text>
          <Input
            id="firstName"
            value={profileInfo.firstName}
            onChange={(e) => handleUpdateSettings("firstName", e.target.value)}
            variant="outline"
            placeholder="Your Name"
            focusBorderColor="primary.500"
          />
        </Box>

        {/* Company Name */}
        <Box mt={4}>
          <Text fontSize="sm" mb={1} fontWeight={500}>
            Your Company Name
          </Text>
          <Input
            id="companyName"
            value={profileInfo.companyName}
            onChange={(e) =>
              handleUpdateSettings("companyName", e.target.value)
            }
            variant="outline"
            placeholder="Your Company Name"
            focusBorderColor="primary.500"
          />
        </Box>

        {/* Company Description */}
        <Box mt={4}>
          <Text fontSize="sm" mb={1} fontWeight={500}>
            Your Company Description
          </Text>
          <Textarea
            id="companyDescription"
            value={profileInfo.companyDescription}
            onChange={(e) =>
              handleUpdateSettings("companyDescription", e.target.value)
            }
            variant="outline"
            placeholder="Company Description"
            focusBorderColor="primary.500"
          />
        </Box>

        {/* Call to Action */}
        <Box mt={4}>
          <Text fontSize="sm" mb={1} fontWeight={500}>
            Call to Action
          </Text>
          <Input
            id="callToAction"
            value={profileInfo.callToAction}
            onChange={(e) =>
              handleUpdateSettings("callToAction", e.target.value)
            }
            variant="outline"
            placeholder="Call to Action"
            focusBorderColor="primary.500"
          />
        </Box>
      </div>
    </div>
  );
};

export default Settings;
