import React from "react";
import { Handle, Position } from "@xyflow/react";

import { CustomNodeProps } from "@/components/Common/AutoPilot/types";
import CampaignEmailNode from "./CampaignEmailNode";

const BreakUp: React.FC<CustomNodeProps> = () => {
  return (
    <>
      <Handle type="target" position={Position.Top} />
      <CampaignEmailNode title="Break Up" subtitle="Third Prompt" />
    </>
  );
};

export default BreakUp;
