import campaignService from "@/services/campaign.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useDeleteLead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      campaignId,
      leadId,
    }: {
      campaignId: number;
      leadId: number;
    }) => campaignService.deleteLead(campaignId, leadId),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["leadList"] });
      }, 1000);
    },
  });
};

export default useDeleteLead;
