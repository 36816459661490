import React from "react";
import ModalIcon from "@/assets/svgs/ai-agent-modal-icon.svg";
import CampaignEmailIcon from "@/assets/svgs/campaign-email.svg";

interface CardProps {
  rightIcon?: string;
  title: string;
  subtitle: string;
}

const CampaignEmailNode: React.FC<CardProps> = ({
  rightIcon,
  title,
  subtitle,
}) => {
  return (
    <div
      className={`w-[330px] rounded-[8px] border text-center border-[#D0D4DC] bg-[#fff]`}
    >
      <div className="flex items-center justify-between px-[16px] py-[12px]">
        <div className="flex items-center gap-5 text-center">
          <img src={CampaignEmailIcon} alt="" height={40} width={40} />
          <div>
            <p className="grow truncate text-[16px] font-[600]">{title}</p>
            <p className="text-[14px] font-medium text-[#7E889F]">{subtitle}</p>
          </div>
        </div>
        {rightIcon && <img src={rightIcon} alt="" className="size-[24px]" />}
      </div>
      <div className="px-[12px] py-[8px] bg-[#F5F3FF] flex items-center justify-center gap-2 rounded-b-[8px]">
        <img src={ModalIcon} height={20} width={20} />
        <p className="text-[14px] text-[#7E889F]">Nia Generated</p>
      </div>
    </div>
  );
};

export default CampaignEmailNode;
