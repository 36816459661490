import campaignService from "@/services/campaign.service";
import { useCampaignStore } from "@/stores/campaigns.store";
import { useQuery } from "@tanstack/react-query";

const useCampaignsDetails = (id: number) => {
  const updateState = useCampaignStore((state) => state.updateState);
  return useQuery({
    queryKey: ["campaignDetails", id],
    queryFn: async () => {
      const response = await campaignService.getCampaignDetails(id);
      updateState({ campaignDetails: response?.data || {} });
      return response;
    },
  });
};

export default useCampaignsDetails;
