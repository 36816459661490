import campaignService from "@/services/campaign.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useDeleteCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: number }) =>
      campaignService.deleteCampaign(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["campaignsList"] });
      }, 1000);
    },
  });
};

export default useDeleteCampaign;
