import React from "react";
import { Handle, Position } from "@xyflow/react";

import { CustomNodeProps } from "@/components/Common/AutoPilot/types";
import CampaignEmailNode from "./CampaignEmailNode";

const IntroductionEmail: React.FC<CustomNodeProps> = () => {
  return (
    <>
      <CampaignEmailNode title="Introduction Email" subtitle="First Prompt" />

      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Bottom} id="b" />
    </>
  );
};

export default IntroductionEmail;
