import campaignService from "@/services/campaign.service";
import { useQuery } from "@tanstack/react-query";

const useCampaignsList = () => {
  return useQuery({
    queryKey: ["campaignsList"],
    queryFn: async () => campaignService.getCampaigns(),
  });
};

export default useCampaignsList;
