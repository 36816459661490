import { FormEvent, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  InputGroup,
  IconButton,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import { isValidEmail } from "@/lib/utils";
import Icons from "../Icons";
import useGetUserCampaignDetails from "./hooks/useGetUserCampaignDetails";
import CampaignsGrid from "./list";

const AdminCampaignManager = () => {
  const [email, setEmail] = useState("");
  const [searchedEmail, setSearchedEmail] = useState("");
  const toast = useToast();

  const { data: userCampaignDetails, isLoading } =
    useGetUserCampaignDetails(searchedEmail);

  const handleSearchUser = (e: FormEvent) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      toast({
        title: "Please enter a valid email address.",
        status: "error",
        duration: 2000,
      });
      return;
    }
    setSearchedEmail(email);
  };

  return (
    <form className="p-8" onSubmit={handleSearchUser}>
      <FormControl id="userEmail" mb={4}>
        <FormLabel>Search User by Email</FormLabel>
        <InputGroup>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter user email"
          />
          <InputRightElement>
            <IconButton
              type="submit"
              isDisabled={!email}
              variant={"ghost"}
              size={"sm"}
              icon={<Icons.SearchIcon />}
              onClick={handleSearchUser}
              colorScheme="purple"
              aria-label={""}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Divider />
      {isLoading ? (
        <Spinner />
      ) : (
        <Box className="mt-4">
          {userCampaignDetails?.data && (
            <CampaignsGrid campaigns={userCampaignDetails?.data || []} />
          )}
        </Box>
      )}
    </form>
  );
};

export default AdminCampaignManager;
