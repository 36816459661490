import { isValidEmail } from "@/lib/utils";
import campaignService from "@/services/campaign.service";
import { useQuery } from "@tanstack/react-query";

const useGetUserCampaignDetails = (email: string) => {
  return useQuery({
    queryKey: ["userCampaignDetails", email],
    queryFn: async () => {
      if (email) {
        const response = await campaignService.getUserCampaignDetails(email);
        return response.data;
      }
    },
    enabled: isValidEmail(email),
  });
};

export default useGetUserCampaignDetails;
