import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, Spinner, Switch, Tooltip, useToast } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import { ApiKey } from "@/types/apiKey.types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMutation } from "@tanstack/react-query";
import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";
import SelectSenderAccounts from "./SelectSenderAccounts";

interface CampaignsGridProps {
  campaigns: any;
}

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

const CampaignsGrid: React.FC<CampaignsGridProps> = (props) => {
  const toast = useToast();
  //   const [gridApi, setGridApi] = useState<GridApi<ApiKey> | null>(null);

  const { mutateAsync: campaignDetailsMutateAsync, isPending } = useMutation({
    mutationFn: (payload: any) =>
      campaignService.updateCampaignDetails(payload),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["userCampaignDetails"] });
    },
  });

  const manageFullAccess = async (campaignId: number, status: boolean) => {
    await campaignDetailsMutateAsync(
      { campaignId, haveFullAccess: status },
      {
        onSuccess(response: any) {
          if (response?.success) {
            toast({
              title: "Success",
              description: "User access updated successfully!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Error",
              description:
                response?.error?.message || "Failed to update user access!",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        },
      },
    );
  };

  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: "Campaign Name",
          field: "name",
          sortable: true,
          filter: true,
          width: 300,
          cellRenderer: (params: any) => (
            <div className="flex flex-row items-center truncate">
              <span className="ml-2 truncate">{params.value}</span>
            </div>
          ),
        },
        {
          headerName: "Create At",
          field: "createdAt",
          sortable: true,
          filter: true,
          width: 300,
          cellRenderer: (params: any) => (
            <Tooltip label={new Date(params.value).toLocaleString()}>
              {dayjs(params.value).fromNow()}
            </Tooltip>
          ),
        },
        {
          headerName: "Have Full Access?",
          field: "haveFullAccess",
          sortable: true,
          filter: true,
          width: 200,
          cellRenderer: (params: any) => (
            <Switch
              isChecked={params.value}
              size="md"
              onChange={(e) => {
                manageFullAccess(params.data.campaignId, e.target.checked);
              }}
              id="sequence-switch"
              className="text-[14px] flex font-medium"
            />
          ),
        },
        {
          headerName: "Sender Accounts",
          field: "senderAccounts",
          sortable: true,
          filter: true,
          width: 400,
          cellRenderer: (params: any) => (
            <div className="flex items-center gap-4">
              <p>{`${params.data?.senderAccounts?.length} accounts`}</p>
              <SelectSenderAccounts campaign={params.data || {}} />
            </div>
          ),
        },
      ] as ColDef<ApiKey>[],
    [],
  );

  //   const onGridReady = (params: any) => {
  //     setGridApi(params.api);
  //   };

  return (
    <Box
      className="relative ag-theme-quartz"
      style={{ height: "400px", width: "100%" }}
    >
      {isPending && (
        <Box className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-10">
          <Spinner size="xl" color="blue.500" />
        </Box>
      )}
      <AgGridReact
        columnDefs={columnDefs}
        rowData={props.campaigns ?? []}
        rowSelection="multiple"
        suppressRowClickSelection
        domLayout="autoHeight"
      />
    </Box>
  );
};

export default CampaignsGrid;
