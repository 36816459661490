const CampaignStatus = ({ status }: { status: string }) => {
  return (
    <div className="h-full flex items-center">
      <div
        className={`!h-[24px] text-[12px] font-medium w-fit px-[12px] rounded-[16px] flex items-center justify-center ${status === "PAUSED" ? "bg-[#FFF9EA] text-[#FF791C]" : status === "ACTIVE" || status === "COMPLETE" ? "bg-[#EBFFEF] text-[#00B41D]" : "bg-[#F0EFF1] text-[#373F51]"}`}
      >
        {status}
      </div>
    </div>
  );
};

export default CampaignStatus;
