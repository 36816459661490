import { useEffect, useState } from "react";

import {
  Input,
  Box,
  Text,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Divider,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import { Option } from "../Common/MultiSelectDropdown/types";
import { timezones, weekDays } from "./constants";

import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";

import Icons from "../Icons";
import ScheduleCampaignImg from "@/assets/svgs/schedule-campaign.svg";

const ScheduleCampaign = ({ campaign }: { campaign: any }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [timezone, setTimezone] = useState<Option | null>(null);
  const [daysOfTheWeek, setDaysOfTheWeek] = useState<Option[] | null>(null);
  const [minTimeBtwEmails, setMinTimeBtwEmails] = useState(3);
  const [newLeadsPerDay, setNewLeadsPerDay] = useState(1);
  const [startTime, setStartTime] = useState("");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  const handleOnClose = () => {
    setTimezone(null);
    setDaysOfTheWeek(null);
    setMinTimeBtwEmails(3);
    setNewLeadsPerDay(1);
    setStartHour("");
    setEndHour("");
    setStartTime("");
    onClose();
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: any) => campaignService.scheduleCampaign(payload),
  });

  const handleScheduleCampaign = async () => {
    const payload = {
      campaignId: campaign.id,
      timezone: timezone?.value || "",
      daysOfTheWeek: daysOfTheWeek?.map((item: Option) =>
        Number(item.value),
      ) || [0],
      startHour,
      endHour,
      startTime,
      newLeadsPerDay,
      minTimeBtwEmails,
    };
    await mutateAsync(payload, {
      onSuccess(response: any) {
        if (response?.success) {
          setTimeout(() => {
            queryClient.refetchQueries({ queryKey: ["campaignDetails"] });
          }, 1000);
          toast({
            title: "Success",
            description: "Campaign scheduled successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          handleOnClose();
        } else {
          toast({
            title: "Error",
            description:
              response?.error?.message || "Failed to schedule campaign!",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
    });
  };

  useEffect(() => {
    setStartHour(campaign?.scheduler_cron_value?.startHour || "");
    setEndHour(campaign?.scheduler_cron_value?.endHour || "");
    const selectedTimeZone = timezones?.find(
      (item) => item.value === campaign?.scheduler_cron_value?.tz,
    );
    setTimezone(selectedTimeZone || null);
    setMinTimeBtwEmails(campaign?.min_time_btwn_emails || 3);
    setNewLeadsPerDay(campaign?.max_leads_per_day || 0);
    const selectedDays = weekDays?.filter((item) =>
      campaign?.scheduler_cron_value?.days?.includes(Number(item.value)),
    );
    setDaysOfTheWeek(selectedDays);
  }, [campaign, isOpen]);

  return (
    <div>
      <Button
        // variant={"outline"}
        size={"sm"}
        w={150}
        onClick={onOpen}
        className="!bg-light !text-textPurple !border-none h-[40px] rounded-md"
        leftIcon={<Icons.ScheduleIcon />}
      >
        Schedule
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        motionPreset="scale"
        size={"3xl"}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent h={"90%"}>
          <ModalHeader className="flex items-center justify-center bg-[#F5F3FF] ">
            <div className="flex items-center flex-col">
              <img src={ScheduleCampaignImg} alt="" />
              <p className="text-[20px] font-medium">Schedule</p>
              <p className="text-[14px] text-[#0F0D1C] font-[400]">
                Choose your workflow automation
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody className={`p-4 overflow-auto custom-scrollbar`}>
            <div className="flex flex-col gap-6">
              <div>
                <div className="flex items-center justify-between pb-0.5">
                  <p className="mb-1 text-[14px] font-medium text-[#0F0D1C]">
                    Choose a timezone
                  </p>
                </div>
                <MultiSelectDropdown
                  isMulti={false}
                  placeholder="choose a timezone"
                  closeMenuOnSelect
                  options={timezones}
                  value={timezone}
                  onChange={(option) => setTimezone(option as Option)}
                />
              </div>

              <div>
                <div className="flex items-center justify-between pb-0.5">
                  <p className="mb-1 text-[14px] font-medium text-[#0F0D1C]">
                    Workflow Cadence
                  </p>
                </div>
                <MultiSelectDropdown
                  isMulti={true}
                  placeholder="select the days"
                  options={weekDays}
                  value={daysOfTheWeek}
                  onChange={(option) => setDaysOfTheWeek(option as Option[])}
                />
              </div>

              <div>
                <div className="flex items-center justify-between pb-0.5">
                  <p className="mb-1 text-[14px] font-medium text-[#0F0D1C]">
                    Time Period Between Sequences
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <Box className="flex items-center bg-[#fff] border border-[#D0D4DC] rounded-[4px] gap-4">
                    <Text fontSize="sm" color="gray.500" className="px-[20px]">
                      From:
                    </Text>
                    <Input
                      type="time"
                      value={startHour}
                      onChange={(e) => setStartHour(e.target.value)}
                      size="sm"
                      className="!bg-[#F9F8F9] !py-[16px] !px-[20px] !border-none  !rounded-r-[4px]"
                    />
                  </Box>

                  <Box className="flex items-center bg-[#fff] border border-[#D0D4DC] rounded-[4px] gap-4">
                    <Text fontSize="sm" color="gray.500" className="px-[20px]">
                      To:
                    </Text>
                    <Input
                      type="time"
                      value={endHour}
                      onChange={(e) => setEndHour(e.target.value)}
                      size="sm"
                      className="!bg-[#F9F8F9] !py-[16px] !px-[20px] !border-none  !rounded-r-[4px]"
                    />
                  </Box>

                  <Box className="flex items-center bg-[#fff] border border-[#D0D4DC] rounded-[4px] gap-4">
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      className="px-[20px] !w-[150px]"
                    >
                      Every (mins):
                    </Text>
                    <Input
                      type="number"
                      value={minTimeBtwEmails}
                      onChange={(e) =>
                        setMinTimeBtwEmails(Number(e.target.value))
                      }
                      min={3}
                      size="sm"
                      className="!bg-[#F9F8F9] !py-[16px] !px-[20px] !border-none !w-[100px] !rounded-r-[4px]"
                    />
                  </Box>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between pb-0.5">
                  <p className="mb-1 text-[14px] font-medium text-[#0F0D1C]">
                    Campaign Start Date
                  </p>
                </div>
                <Input
                  type="date"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  size="sm"
                  className="border-gray-300 rounded-md !py-[20px] !bg-[#F9F8F9] !rounded-[6px]"
                />
              </div>

              <div>
                <div className="flex items-center justify-between pb-0.5">
                  <p className="mb-1 text-[14px] font-medium text-[#0F0D1C]">
                    Max number of leads (Daily limit)
                  </p>
                </div>
                <Input
                  w={"fit-content"}
                  type="number"
                  value={newLeadsPerDay}
                  onChange={(e) => setNewLeadsPerDay(Number(e.target.value))}
                  min={1}
                  size="sm"
                  className="border-gray-300 !rounded-md"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-full flex items-center justify-center gap-3">
              <Button
                onClick={onClose}
                variant={"outline"}
                leftIcon={<Icons.CloseIcon />}
                size={"sm"}
              >
                Cancel
              </Button>
              {campaign?.status === "ACTIVE" ? (
                <Tooltip label="Cannot update schedule settings when campaign is ACTIVE">
                  <Button
                    onClick={handleScheduleCampaign}
                    isLoading={isPending}
                    isDisabled
                    bg={"#C03EDC"}
                    color={"#fff"}
                    size={"sm"}
                    className="hover:!bg-[#C03EDC] hover:!shadow-md"
                  >
                    Save
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  rightIcon={<Icons.CheckCircleIcon />}
                  onClick={handleScheduleCampaign}
                  isLoading={isPending}
                  colorScheme="purple"
                  size={"sm"}
                  className="hover:!bg-[#C03EDC] hover:!shadow-md"
                >
                  Save
                </Button>
              )}
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ScheduleCampaign;
