import Sidebar from "../../PeopleSearch/Sidebar";
import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PeopleSearchResultTable from "@/components/PeopleSearch/PeopleSearchResultTable";
import { useSearchLeadsStore } from "@/components/PeopleSearch/store";
import { queryClient } from "@/lib/queryClient";

const ImportLeadsFromScratch = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );

  //TODO: FIX this

  // const campaignDetails = useCampaignStore((state) => state.campaignDetails);
  // const getFirstStaleTab = useTabStore(
  //   (state) => state.getOrCreateFirstStateTab,
  // );

  // useEffect(() => {
  //   if (campaignDetails) {
  //     const tab = getFirstStaleTab();
  //     tab.store.setState({
  //       peopleSearchPayload: campaignDetails?.leadPreferences,
  //     });
  //     useTabStore.getState().activateTab(tab.id);
  //   }
  // }, [campaignDetails]);

  const handleAISDR = () => {
    onClose();
    queryClient.refetchQueries({
      queryKey: ["leadList"],
    });
    toast({
      title: "Success",
      description: "Successfully added leads!",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  useEffect(() => {
    if (searchLeadsResult?.profiles?.length) onOpen();
  }, [searchLeadsResult]);

  return (
    <div className="mt-6 w-[80%]">
      <div className="flex items-center justify-between mb-4">
        <p className="text-[16px] font-medium text-[#0F0D1C]">
          Build leads from scratch
        </p>
        {searchLeadsResult?.profiles?.length && (
          <Button onClick={onOpen} size={"sm"} variant={"outline"}>
            Show Leads
          </Button>
        )}
      </div>
      <Sidebar isForAISDR />
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody className="overflow-y-auto !pb-[60px] !pt-[40px]">
            <PeopleSearchResultTable
              uniqueSocketId="campaign_1"
              isForAISDR
              handleAISDR={handleAISDR}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ImportLeadsFromScratch;
